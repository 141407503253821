const PAYOUT_STRATEGY = {
  zeroHash: 'ZeroHash',
};

export class FlowDetails {
  constructor(payoutStrategy, isPayoutTermsAcceptanceRequired) {
    this.payoutStrategy = payoutStrategy;
    this.payoutTermsAcceptanceRequired = isPayoutTermsAcceptanceRequired;
  }

  isPayoutTermsAcceptanceRequired() {
    return this.payoutTermsAcceptanceRequired;
  }

  isZerohashPayoutStrategy() {
    return this.payoutStrategy === PAYOUT_STRATEGY.zeroHash;
  }
}
