<template>
  <i :class="[`icon icon-${icon}`]" />
</template>

<script>
export default {
  name: 'Icon',

  props: {
    icon: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
.icon {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e900";
}
.icon-arrow-dd:before {
  content: "\e901";
}
.icon-alert:before {
  content: "\e902";
  color: #e73c40;
}
.icon-verifyed:before {
  content: "\e903";
  color: #47a136;
}
.icon-check-double:before {
  content: "\e904";
}
.icon-limits:before {
  content: "\e905";
}
.icon-gift:before {
  content: "\e906";
}
.icon-notifications:before {
  content: "\e907";
}
.icon-off:before {
  content: "\e908";
}
.icon-referal:before {
  content: "\e909";
}
.icon-buy:before {
  content: "\e90a";
}
.icon-arrow-bottom:before {
  content: "\e90b";
}
.icon-arrow-left:before {
  content: "\e90c";
}
.icon-arrow-right:before {
  content: "\e90d";
}
.icon-arrow-top:before {
  content: "\e90e";
}
.icon-webcamera:before {
  content: "\e90f";
}
.icon-file:before {
  content: "\e910";
}
.icon-close:before {
  content: "\e911";
}
.icon-checkbox:before {
  content: "\e912";
}
.icon-question-circle:before {
  content: "\e913";
}
.icon-arrow-long-left:before {
  content: "\e914";
}
.icon-arrow-long-right:before {
  content: "\e915";
}
.icon-arrow-long-up:before {
  content: "\e916";
}
.icon-arrow-long-down:before {
  content: "\e917";
}
.icon-wallet:before {
  content: "\e918";
}
.icon-paybis-logo:before {
  content: "\e919";
}
.icon-plus:before {
  content: "\e91a";
}
.icon-shield:before {
  content: "\e91b";
}
.icon-dots:before {
  content: "\e91c";
}
.icon-trash:before {
  content: "\e91d";
}
.icon-edit:before {
  content: "\e91e";
}
.icon-approximately:before {
  content: "\e91f";
}
.icon-info-circle:before {
  content: "\e920";
}
.icon-twitter:before {
  content: "\e921";
}
.icon-fb-messenger:before {
  content: "\e922";
}
.icon-facebook:before {
  content: "\e923";
}
.icon-envelope:before {
  content: "\e924";
}
.icon-copy:before {
  content: "\e925";
}
.icon-arrow-triangle-top:before {
  content: "\e926";
}
.icon-arrow-triangle-right:before {
  content: "\e927";
}
.icon-arrow-triangle-left:before {
  content: "\e928";
}
.icon-phone:before {
  content: "\e929";
}
.icon-desktop:before {
  content: "\e92a";
}
.icon-clock:before {
  content: "\e92b";
}
.icon-lock:before {
  content: "\e92c";
}
.icon-search:before {
  content: "\e92d";
}
.icon-exchange:before {
  content: "\e92e";
}
.icon-card:before {
  content: "\e92f";
}
.icon-earth:before {
  content: "\e930";
}
.icon-burger:before {
  content: "\e931";
}
.icon-close-lg:before {
  content: "\e932";
}
.icon-dots-h:before {
  content: "\e933";
}
.icon-doublearrow:before {
  content: "\e934";
}
.icon-doublearrow-v:before {
  content: "\e935";
}
.icon-vk:before {
  content: "\e936";
}
.icon-google:before {
  content: "\e937";
}
.icon-podcast:before {
  content: "\e938";
}
.icon-facebook-f:before {
  content: "\e939";
}
.icon-instagram:before {
  content: "\e93a";
}
.icon-reddit:before {
  content: "\e93b";
}
.icon-youtube:before {
  content: "\e93c";
}
.icon-volume:before {
  content: "\e93d";
}
.icon-book:before {
  content: "\e93e";
}
.icon-filter:before {
  content: "\e93f";
}
.icon-print:before {
  content: "\e940";
}
.icon-linkedin:before {
  content: "\e941";
}
.icon-chat:before {
  content: "\e942";
}
.icon-minus:before {
  content: "\e943";
}
.icon-portal:before {
  content: "\e944";
}
.icon-skype:before {
  content: "\e945";
}
.icon-maximize:before {
  content: "\e946";
}
.icon-minimize:before {
  content: "\e947";
}
.icon-qrcode:before {
  content: '\e953';
}
</style>
