import { getTimerFromTimestamp } from '../utils/getTimerFromTimestamp';

class AuthError extends Error {
  constructor({ data, status }) {
    const { code, message, errors } = data;

    super(message);

    this.status = status;
    this.isCritical = status === 404 || status >= 500;
    this.statusCode = code;
    this.message = message;
    this.errors = errors;
  }
}

class OtpError extends Error {
  constructor({ data, status }) {
    const { code, attempts, expirationTime, canBeResentAt } = data;

    super('OTP validation failed');

    this.status = status;
    this.statusCode = attempts === 0 ? 'wrong-otp-limit-exceeded' : code;
    this.attempts = attempts;
    this.expirationTime = expirationTime;
    this.canBeResentAt = canBeResentAt;
  }
}

class TooEarlyError extends Error {
  constructor({ data, status }) {
    const { code, canBeResentAfter } = data;

    super('Session still in progress');

    this.status = status;
    this.statusCode = code;
    this.canBeResentAfter = canBeResentAfter;
  }

  getResendTimer() {
    const timeLeft = new Date(this.canBeResentAfter) - new Date();

    return getTimerFromTimestamp(timeLeft);
  }
}

export {
  AuthError, OtpError, TooEarlyError,
};
