export function getAllowedNetworkUrls() {
    return [
        window.location.origin,
        'https://widget.paybis.com',
        'https://widget.sandbox.paybis.com',
        import.meta.env.VITE_APP_AUTH_SERVICE_PUBLIC_URL,
        import.meta.env.VITE_APP_CARD_ENTRY_URL,
        import.meta.env.VITE_APP_PROCESSING_API_URL,
        import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL,
    ].map(el => {
        if (el.includes('/')) {
            const url = el
                .replaceAll('/', '\\/')
                .replaceAll('.', '\\.');
            return new RegExp(`^${url}`);
        }
        return el;
    });
}
