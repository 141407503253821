const statuses = {
  STATUS_CREATED: 'created',
  STATUS_NOT_CREATED: 'not_created',
  STATUS_VERIFIED: 'verified',
  STATUS_NOT_VERIFIED: 'not_verified',
};

export default class VerificationStatus {
  constructor(status = null) {
    this.status = status;
  }

  isCreated() {
    return this.status === null || this.status === statuses.STATUS_CREATED;
  }

  isNotCreated() {
    return this.status === statuses.STATUS_NOT_CREATED;
  }

  isVerified() {
    return this.status === statuses.STATUS_VERIFIED;
  }

  isNotVerified() {
    return this.status === statuses.STATUS_NOT_VERIFIED;
  }
}
