import CheckoutPaymentProcessor from './checkout/Checkout';
import WorldpayPaymentProcessor from './worldpay/Worldpay';
import BridgerpayPaymentProcessor from './bridgerpay/Bridgerpay';

export class PaymentProcessorFactory {
  constructor(id, cardId, invoice, cvv) {
    this.id = id;
    this.cardId = cardId;
    this.invoice = invoice;
    this.cvv = cvv;
  }

  build() {
    switch (this.id) {
      case 'e41b44b7-5767-48a8-9c03-78542e85cf6b': // checkout
        return new BridgerpayPaymentProcessor(
          this.cardId,
          this.invoice,
          this.cvv,
        );
      case '7e7612e5-387e-4a3a-8bce-103447eaa749': // checkout
        return new CheckoutPaymentProcessor(
          this.cardId,
          this.invoice,
          this.cvv,
        );
      case '13832147-b7bc-4c0c-bdd7-20f0f79070ea': // worldpay
        return new WorldpayPaymentProcessor(this.cardId, this.invoice);
      default:
        throw new Error(`Payment processor with id ${this.id} is not found`);
    }
  }
}
