import Api from '../api';
import riskJsService from '../risk-js-service';

const apiUrl = `${import.meta.env.VITE_APP_CARD_ENTRY_URL}/public/card-payment-service/v1`; // eslint-disable-line no-undef

export default {
  getPreferablePaymentProcessor({ cardId, currency, invoice }) {
    const searchParams = new URLSearchParams();
    searchParams.set('cardId', cardId);
    searchParams.set('currency', currency);
    searchParams.set('invoice', invoice);
    const url = `${apiUrl}/payment/payment-processor?${searchParams.toString()}`;
    return Api().get(url);
  },
  async checkoutPaymentCreate({ cardId, invoice, cvv, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/create`;
    const deviceId = await riskJsService.getDeviceSessionId();

    return Api().post(url, {
      card_id: cardId,
      invoice,
      cvv,
      challenge_return_url: returnUrl,
      deviceId,
    });
  },
  async bridgerpayPaymentCreate({ cardId, invoice, cvv, returnUrl, ddc }) {
    const url = `${apiUrl}/payment/bridgerpay/create`;

    const CheckoutCom = await riskJsService.getBridgerpayDeviceSessionId();
    if (CheckoutCom) {
      if (ddc === null) ddc = { CheckoutCom };
      if (ddc) ddc.CheckoutCom = CheckoutCom;
    }

    return Api().post(url, {
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
      cvv,
      ddc,
    });
  },
  getDdc({ cardId }) {
    const url = `${apiUrl}/payment/world-pay/ddc/${cardId}`;
    return Api().get(url);
  },
  getBridgerpayDdc({ cardId, invoice }) {
    const url = `${apiUrl}/payment/bridgerpay/ddc`;
    return Api().post(url, {
      card_id: cardId,
      invoice,
    });
  },
  initialPayment({
    cardId,
    invoice,
    dfReferenceId,
    cvv,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/initial-payment`;
    return Api().post(url, {
      card_id: cardId,
      df_reference_id: dfReferenceId,
      cvv,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  getPaymentStatus({ paymentId }) {
    const url = `${apiUrl}/payment/${paymentId}/status`;
    return Api().get(url);
  },
  getActivePaymentId({ invoice }) {
    const url = `${apiUrl}/payment/invoice/${invoice}`;
    return Api().get(url);
  },
  validatePaymentSession({
                           validationUrl,
                           invoice,
                           domainName = undefined,
                           displayName = undefined,
                         }) {
    const url = `${apiUrl}/payment/apple-pay/payment-session`;
    return Api().post(url, {
      validationUrl,
      invoice,
      domainName,
      displayName,
    });
  },
  initialWorldpayApplePayPayment({
    paymentToken,
    cardId,
    invoice,
    dfReferenceId,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/apple-pay/initial-payment`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      df_reference_id: dfReferenceId,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  initialWorldpayGooglePayPayment({
    paymentToken,
    cardId,
    invoice,
    dfReferenceId,
    browserLanguage,
    returnUrl,
  }) {
    const url = `${apiUrl}/payment/world-pay/google-pay/initial-payment`;
    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      df_reference_id: dfReferenceId,
      invoice,
      browser_language: browserLanguage,
      return_url: returnUrl,
    });
  },
  async createCheckoutApplePayPayment({ paymentToken, cardId, invoice, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/apple-pay/create`;
    const deviceId = await riskJsService.getDeviceSessionId();

    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
      deviceId,
    });
  },
  async createCheckoutGooglePayPayment({ paymentToken, cardId, invoice, returnUrl }) {
    const url = `${apiUrl}/payment/checkout/google-pay/create`;
    const deviceId = await riskJsService.getDeviceSessionId();

    return Api().post(url, {
      payment_token: paymentToken,
      card_id: cardId,
      invoice,
      challenge_return_url: returnUrl,
      deviceId,
    });
  },
  isPayoutAllowedByCardId({ cardId, invoice }) {
    const url = `${apiUrl}/payout/check/${cardId}`;
    return Api().get(url, { params: { invoice } });
  },
  getRiskSettings() {
    return Api().get(`${apiUrl}/payment/checkout/risk`);
  },
};
