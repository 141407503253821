import { initGooglePay as initGooglePayExternal } from '../services/googleInitService';
import { toRaw } from 'vue';

export default {
  namespaced: true,
  state: {
    initiatingGooglePay: false,
    canUseGooglePay: false,
    googlePaymentDataRequest: {},
  },
  getters: {
    initiatingGooglePay: state => state.initiatingGooglePay,
    canUseGooglePay: state => state.canUseGooglePay,
    googlePaymentDataRequest: state => state.googlePaymentDataRequest,
  },
  mutations: {
    setInitiatingGooglePay(state, payload) {
      state.initiatingGooglePay = payload;
    },
    setCanUseGooglePay(state, payload) {
      state.canUseGooglePay = payload;
    },
    setGooglePaymentDataRequest(state, payload) {
      state.googlePaymentDataRequest = payload;
    },
  },
  actions: {
    async initGooglePay({ commit, getters, rootGetters }) {
      if (getters.initiatingGooglePay) {
        return;
      }

      const googlePaySettings = rootGetters['card/googlePaySettings'];
      const paymentAmount = rootGetters['quote/paymentAmount'];

      commit('setInitiatingGooglePay', true);

      try {
        await initGooglePayExternal({
          googlePaySettings: toRaw(googlePaySettings),
          paymentAmount,
          onSuccess: () => {
            commit('setCanUseGooglePay', true);
            commit('setInitiatingGooglePay', false);
          },
          onPaymentRequestDone: PaymentDataRequest => {
            commit('setGooglePaymentDataRequest', PaymentDataRequest);
          },
          onReject: () => {
            commit('setInitiatingGooglePay', false);
          },
        });
      } finally {
        commit('setInitiatingGooglePay', false);
      }
    },
  },
};
