export const VerificationStatus = {
  STATUS_NONE: 'none',
  STATUS_PENDING: 'pending',
  STATUS_REVIEW: 'review',
  STATUS_APPROVED: 'approved',
  STATUS_REJECTED: 'rejected',
};

export const VerificationFieldStatus = {
  STATUS_UNVERIFIED: 'unverified',
  STATUS_UPLOADED: 'uploaded',
  STATUS_ACCEPTED: 'accepted',
  STATUS_REJECTED: 'rejected',
};

export const VerificationFieldStatusLegacy = {
  STATUS_UNVERIFIED: 0,
  STATUS_UPLOADED: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
};

export const VerificationStatusLegacy = {
  STATUS_UNVERIFIED: 0,
  STATUS_VERIFIED: 1,
  STATUS_REJECTED: 2,
  STATUS_UNDER_REVIEW: 3,
};
