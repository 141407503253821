import Field from '../field';

export class ExpirationDateField extends Field {
  getFormattedValue() {
    const [month, year] = this.value.split('/');
    if (month && month.length === 2 && year && year.length === 2) {
      return (
        `${month
        }/${
          new Date()
            .getFullYear()
            .toString()
            .substr(0, 2)
        }${year.toString()}`
      );
    }

    return '';
  }
}

export class SaveField extends Field {
  getValue() {
    return super.getValue() === '' || super.getValue() === true;
  }
}
