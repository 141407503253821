import MoneyServiceType from './money-service/type';

export default class MoneyService {
  constructor(
    name = null,
    icon = null,
    slug = null,
    type = null,
    iconName = null,
  ) {
    this.name = name;
    // TODO: remove icon, since new money services use iconName attribute for sprite
    this.icon = icon;
    this.slug = slug;
    this.type = type;
    this.iconName = iconName;
  }

  getName() {
    return this.name;
  }

  getIcon() {
    return this.icon;
  }

  getIconName() {
    return this.iconName;
  }

  getSlug() {
    return this.slug;
  }

  getType() {
    return this.type;
  }

  isBank() {
    return this.type === MoneyServiceType.TYPE_BANK;
  }

  isBankSwift() {
    return this.type === MoneyServiceType.TYPE_BANK_SWIFT;
  }

  isCrypto() {
    return this.type === MoneyServiceType.TYPE_CRYPTO;
  }

  isElectronic() {
    return this.type === MoneyServiceType.TYPE_ELECTRONIC;
  }

  isGiftCard() {
    return this.type === MoneyServiceType.TYPE_GIFT_CARD;
  }

  isNeteller() {
    return this.slug.includes('neteller');
  }

  isSkrill() {
    return this.slug.includes('skrill');
  }

  isBankCard() {
    return (
      this.type === MoneyServiceType.TYPE_CREDIT_CARD
      || (this.slug && this.slug.includes(MoneyServiceType.TYPE_BANK_CARD))
    );
  }

  isApm() {
    return this.type === MoneyServiceType.TYPE_APM;
  }
}
