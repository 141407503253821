<template>
  <div
    ref="listRef"
    class="transaction-history-scrollable-list"
  >
    <div
      v-for="dayGroup in transactions"
      :key="dayGroup.dayTimestamp"
      class="transaction-group"
    >
      <time
        class="transaction-group__date"
      >
        {{ getFormattedTransactionDate(dayGroup.dayTimestamp) }}
      </time>

      <TransactionHistoryItem
        v-for="item in dayGroup.items"
        :key="item.id"
        class="transaction-group__item"
        :transaction="item"
        :selected-invoice="selectedTransactionInvoice"
        @click="selectTransaction(item)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import TransactionHistoryItem from './TransactionHistoryItem';

export default defineComponent({
  name: 'TransactionHistoryScrollableList',

  components: {
    TransactionHistoryItem,
  },

  props: {
    transactions: {
      type: Object,
      required: true,
    },
  },

  emits: ['transaction-item-select'],

  setup: (props, { emit }) => {
    const store = useStore();
    const { t } = useI18n();

    const selectedTransactionInvoice = ref('');

    const getFormattedTransactionDate = timestamp => {
      const isToday = date => {
        const today = new Date();
        return date.toDateString() === today.toDateString();
      };

      const isYesterday = date => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday.toDateString() === date.toDateString();
      };

      const transactionDate = new Date(timestamp * 1000);

      if (isYesterday(transactionDate)) return t('widget.transaction.history.yesterday');

      if (isToday(transactionDate)) return t('widget.transaction.history.today');

      return transactionDate.toLocaleDateString(store.getters.locale, {
        dateStyle: 'medium',
      });
    };

    const selectTransaction = transaction => {
      selectedTransactionInvoice.value = transaction.invoice;
      emit('transaction-item-select', transaction);
    };

    return {
      selectedTransactionInvoice,

      selectTransaction,
      getFormattedTransactionDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.transaction-history-scrollable-list {
  position: relative;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
}

.transaction-group {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &__date {
    font-family: $font-family;
    font-size: rem(14);
    line-height: rem(16);
    color: $black-30;
    display: block;
    border-bottom: 1px solid $black-06;
    padding-bottom: rem(7);

    @media (max-width: $screen-sm-min) {
      font-size: 14px;
    }
  }
}
</style>
