export const TransactionStatus = {
  STATUS_PAYOUT_AUTHORIZATION_PENDING: 'auth-pending',
  STATUS_PAYOUT_AUTHORIZATION_PROCESSING: 'auth-processing',
  STATUS_VERIFICATION: 'verification',
  STATUS_PAYMENT_CHECKS: 'payment-checks',
  STATUS_PAYOUT_DETAILS: 'payout-details',
  STATUS_PAYMENT_DETAILS: 'payment-details',
  STATUS_PAYMENT_WAITING: 'payment-waiting',
  STATUS_PAYMENT_REVIEW: 'payment-review',
  STATUS_PAYOUT_WAITING: 'payout-waiting',
  STATUS_COMPLETED: 'completed',
  STATUS_CANCELLED: 'cancelled',
  STATUS_REJECTED: 'rejected',
  STATUS_PAYMENT_ERROR: 'payment-error',
};
