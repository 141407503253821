import { TransactionFlowStep as steps } from '../models/steps';

export default class {
  constructor(transaction, steps, currentIndex) {
    this.transaction = transaction;
    this.steps = steps;
    this.currentIndex = currentIndex;
  }

  getStepIndex() {
    return 0;
  }

  getCurrentIndex() {
    if (this.currentIndex() > -1) {
      return this.currentIndex();
    }

    return this.getStepIndex();
  }

  getSteps() {
    return this.steps;
  }

  current() {
    return this.getSteps()[0];
  }

  hasNext() {
    return typeof this.getSteps()[this.getCurrentIndex() + 1] !== 'undefined';
  }

  next() {
    if (!this.hasNext()) {
      return;
    }

    return this.getSteps()[this.getCurrentIndex() + 1];
  }

  hasPrev() {
    return typeof this.getSteps()[this.getCurrentIndex() - 1] !== 'undefined';
  }

  prev() {
    if (!this.hasPrev()) {
      return;
    }

    return this.getSteps()[this.getCurrentIndex() - 1];
  }

  hasCancel() {
    return this.transaction.isCancellable();
  }

  cancel() {
    return { name: steps.STEP_CANCELLED };
  }
}
