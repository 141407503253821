export class Fees {
  constructor({ service_fee, network_fee, total_fee, blockchain_fee, fee_discount, currency } = {}) {
    this.serviceFee = service_fee;
    this.networkFee = network_fee;
    this.totalFee = total_fee;
    this.blockchainFee = blockchain_fee;
    this.feeDiscount = fee_discount;
    this.currency = currency;
  }

  getServiceFee() {
    return this.serviceFee;
  }

  getNetworkFee() {
    return this.networkFee;
  }

  getTotalFee() {
    return this.totalFee;
  }

  getFeeDiscount() {
    return this.feeDiscount;
  }

  getCurrency() {
    return this.currency;
  }
}
