import Field from '../field';
import Hint from '../hint';
import { ACCOUNT } from '../../constants/ids-types';

export class PayoutDetailsField extends Field {
  getHint() {
    return new Hint(null);
  }

  isAccount() {
    return this.id === ACCOUNT;
  }
}
