import { TransactionFlowStepFactory } from '../factories/StepFactory';
import NavigationFactory from '../navigation/navigationFactory';

export default {
  namespaced: true,
  state: {
    currentStep: null,
    steps: [],
    navigation: {
      getCurrentIndex() {
      },
      current() {
      },
      hasNext() {
      },
      next() {
      },
      hasPrev() {
      },
      prev() {
      },
      hasCancel() {
      },
      cancel() {
      },
    },
  },
  getters: {
    steps: state => state.steps,
    currentStepIndex: (state, getters) => getters.steps.findIndex(step => step.name === state.currentStep),
    getCurrentIndex: state => state.navigation.getCurrentIndex(),
    current: state => state.navigation.current(),
    hasNext: state => state.navigation.hasNext(),
    next: state => state.navigation.next(),
    hasPrev: state => state.navigation.hasPrev(),
    prev: state => state.navigation.prev(),
    hasCancel: state => state.navigation.hasCancel(),
    cancel: state => state.navigation.cancel(),
  },
  mutations: {
    setNavigation(state, payload) {
      state.navigation = payload;
    },
    setSteps(state, payload) {
      state.steps = payload;
    },
    setCurrentStep(state, payload) {
      state.currentStep = payload;
    },
  },
  actions: {
    init(
      { commit, getters },
      {
        transaction,
        baseVerification,
        extraVerification,
        payment,
        isSumsubVerificationPending,
        isActivePayment,
        isChallenge,
        flowDetails,
        requestHasWallet,
        requestStatus,
      },
    ) {
      try {
        const stepsFactory = new TransactionFlowStepFactory(
          transaction,
          payment,
          extraVerification,
          baseVerification,
        );
        const steps = stepsFactory.build();
        commit('setSteps', steps);

        const navigationFactory = new NavigationFactory(
          transaction,
          baseVerification,
          steps,
          payment,
          isSumsubVerificationPending,
          isActivePayment,
          isChallenge,
          flowDetails,
          requestHasWallet,
          requestStatus,
        );
        const navigation = navigationFactory.getNavigation(
          () => getters.currentStepIndex,
        );
        commit('setNavigation', navigation);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
