import Money from '../../models/money';
import Url from '../../services/url';

const ICON_PLACEHOLDER = 'https://via.placeholder.com/80x80/000000/ffffff';

export default {
  namespaced: true,
  state: {
    payment: {
      name: null,
      icon: ICON_PLACEHOLDER,
      amount: {
        getAmount() {},
        getCurrency() {},
      },
    },
    payout: {
      name: null,
      icon: ICON_PLACEHOLDER,
      amount: {
        getAmount() {},
        getCurrency() {},
      },
      originalAmount: {
        getAmount() {},
        getCurrency() {},
      },
    },
  },
  getters: {
    paymentAmount: state => state.payment.amount,
    payoutAmount: state => state.payout.amount,
    hasQuote: (_, getters) => getters.paymentAmount.getAmount() && getters.payoutAmount.getAmount(),
    quote: state => ({
      sell: {
        icon: state.payment.icon,
        amount: state.payment.amount.getAmount(),
        currency: state.payment.amount.getCurrency(),
        title: state.payment.name,
      },
      buy: {
        icon: state.payout.icon,
        amount: state.payout.amount.getAmount(),
        currency: state.payout.amount.getCurrency(),
        title: state.payout.name,
        originalAmount: state.payout.originalAmount.getAmount(),
      },
    }),
  },
  mutations: {
    setPayment(state, { amount, currency, name, icon }) {
      const payment = { ...state.payment };

      payment.amount = new Money(amount, currency);
      payment.name = name;

      if (icon) {
        payment.icon = icon;
      }

      state.payment = payment;
    },

    setPayout(
      state,
      { amount, original_amount: originalAmount, currency, name, icon },
    ) {
      const payout = { ...state.payout };

      payout.amount = new Money(amount, currency);
      payout.originalAmount = new Money(originalAmount, currency);
      payout.name = name;

      if (icon) {
        payout.icon = icon;
      }

      state.payout = payout;
    },
  },
  actions: {
    init({ commit, dispatch }, { payment, payout }) {
      dispatch('setPayment', {
        amount: payment.amount,
        name: payment.service.name,
        iconName: payment.service.icon_name,
      });
      dispatch('setPayout', {
        amount: payout.amount,
        name: payout.service.name,
        iconName: payout.service.icon_name,
      });
    },
    setPayment({ commit }, { amount, name, iconName }) {
      commit('setPayment', {
        ...amount,
        name,
        icon: Url.getMoneyServiceIconByName(iconName),
      });
    },
    setPayout({ commit }, { amount, name, iconName }) {
      commit('setPayout', {
        ...amount,
        name,
        icon: Url.getMoneyServiceIconByName(iconName),
      });
    },
    reset({ commit }) {
      commit('setPayment', {});
      commit('setPayout', {});
    }
  },
};
