import { MENU_ITEMS_DICTIONARY } from '@/constants/menu';

import LanguageLayout from './Language';
import TermsOfUseLayout from './TermsOfUse';
import TransactionHistoryLayout from './TransactionHistory';

export function getCurrentComponent(itemName) {
  switch (itemName) {
    case MENU_ITEMS_DICTIONARY.LANGUAGE.alias:
      return LanguageLayout;

    case MENU_ITEMS_DICTIONARY.TERMS_OF_USE.alias:
      return TermsOfUseLayout;

    case MENU_ITEMS_DICTIONARY.TRANSACTION_HISTORY.alias:
      return TransactionHistoryLayout;

    default: return null;
  }
}
