import * as Sentry from '@sentry/vue';
import { getCorrelationId } from '@/v1/packages/common/services/correlation';
import { filterUnwantedExceptions, filterOldBrowsers, ignoredErrorMessages, denyUrls } from '@/v1/plugins/sentry-ignore-config';
import TokenService from '@/v1/packages/common/services/token';
import store from '@/v1/store';
import { getAllowedNetworkUrls } from '@/v1/plugins/sentry-config';

const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
const environment = import.meta.env.VITE_APP_DEPLOYMENT_ENV;

export const initSentry = (app, router) => {
  Sentry.init({
    app,
    dsn,
    environment,
    integrations: [new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    })],
    ignoreErrors: ignoredErrorMessages,
    denyUrls,
    beforeSend: (event, hint) => {
      const oldBrowser = filterOldBrowsers();
      if (oldBrowser) return null;

      const unwantedException = filterUnwantedExceptions(event, hint);
      if (unwantedException) return null;

     return event;
    },
    tracesSampleRate: 0.25,
    tracePropagationTargets: [import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL], // XXX: update with services urls that support sentry custom headers (sentry-trace, baggage)
    replaysSessionSampleRate: 0.15,
    replaysOnErrorSampleRate: 1.0,
  });

  setTags({
    correlation_id: getCorrelationId(),
  });
};

export const setTags = tags => {
  Sentry.configureScope(scope => {
    Object.entries(tags).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
  });
};

export const addSentryReplay = () => {
  const client = Sentry.getCurrentHub().getClient();

  if (!client) {
    return;
  }

  const replay = new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
      networkDetailAllowUrls: getAllowedNetworkUrls(),
      networkCaptureBodies: true,
      beforeAddRecordingEvent: event => {
        const user = TokenService.getUser();
        const invoice = store.getters['transaction/invoice'];

        if (user) {
          Sentry.setUser(user);
        }
        if (invoice){
          setTags({ invoice });
        }
        return event;
      },
    });

  client.addIntegration(replay);

  if (store.getters.isUkCustomer) {
    replay.start();
  }
};

export default {
  initSentry,
  setTags,
};
