import { PROMO_CODE } from '@/v1/store/mutations';

const {
  SET_PROMO_CODE,
  SET_CAPTURE,
  SET_ERROR,
} = PROMO_CODE;

export default {
  namespaced: true,

  state: {
    promoCode: null,
    capture: '',
    error: false,
  },

  getters: {
    promoCode: state => state.promoCode,
    capture: state => state.capture,
    error: state => state.error,
  },

  mutations: {
    [SET_PROMO_CODE]: (state, value) => {
      state.promoCode = value;
    },
    [SET_CAPTURE]: (state, value) => {
      state.capture = value;
    },
    [SET_ERROR]: (state, value) => {
      state.error = value;
    },
  },
  actions: {
    setPromoCode({ commit }, promoCode) {
      commit(SET_PROMO_CODE, promoCode);
    },
    setCapture({ commit }, capture) {
      commit(SET_CAPTURE, capture);
    },
    setError({ commit }, flag) {
      commit(SET_ERROR, flag);
    },
    resetPromoCodeState({ commit }, capture) {
      commit(SET_PROMO_CODE, null);
      commit(SET_CAPTURE, capture);
      commit(SET_ERROR, false);
    },
  },
};
