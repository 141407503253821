import Field from '@/v1/packages/common/models/field';
import CountriesDictionary from '@/v1/packages/common/dictionaries/countries';
import StatesDictionary from '@/v1/packages/common/dictionaries/states';

export default class BillingAddress {
  constructor() {
    this.fields = {
      country: new Field({
        id: 'country',
        name: 'country',
        type: 'text',
        value: '',
        options: CountriesDictionary,
      }),
      state: new Field({
        id: 'state',
        name: 'state',
        type: 'text',
        value: null,
        options: StatesDictionary,
      }),
      address: new Field({
        id: 'address',
        name: 'address',
        type: 'text',
        value: '',
      }),
      city: new Field({
        id: 'city',
        name: 'city',
        type: 'text',
        value: '',
      }),
      zip: new Field({
        id: 'zip',
        name: 'zip',
        type: 'text',
        value: '',
      }),
    };
  }

  getFormFields() {
    return this.fields;
  }

  getFormFieldValuesObject() {
    const data = {};
    Object.values(this.fields).forEach(el => {
      data[el.getName()] = el.getValue();
    });
    return data;
  }

  resetFormFields() {
    Object.values(this.fields).forEach(field => {
      field.setValue('');
    });
  }

  resetErrors() {
    Object.values(this.fields).forEach(field => {
      field.setErrorMessage(null);
    });
  }
}
