import BaseVerification from './basic';
import { VerificationStatus } from './status';

export default class ExtraVerification extends BaseVerification {
  constructor(status = null, fields = []) {
    super(status);
    this.fields = fields;
  }

  isReview() {
    return this.status === VerificationStatus.STATUS_REVIEW;
  }

  isRejected() {
    return this.status === VerificationStatus.STATUS_REJECTED;
  }
}
