const VALIDATON_ERROR = 'VALIDATION_ERROR';
const QUOTE_PAYMENT_METHOD_NOT_FOUND = 'QUOTE_PAYMENT_METHOD_NOT_FOUND';
const REQUEST_HAS_INVALID_PRODUCT_ID = 'REQUEST_HAS_INVALID_PRODUCT_ID';

export const isCriticalResponseError = ({ isAxiosError = false, response = {} }) => {
  if (!isAxiosError) {
    // Early return
    return false;
  }

  const { status = 0, data = {} } = response;
  const { code = '' } = data;

  switch (code) {
    case VALIDATON_ERROR:
    case REQUEST_HAS_INVALID_PRODUCT_ID:
      return false;
    case QUOTE_PAYMENT_METHOD_NOT_FOUND:
      return true;
  }

  return status >= 500;
};
