import CardPaymentClient from '../../services/clients/card-payment-client';
import AbstractPaymentProcessor from '../AbstractPaymentProcessor';
import { getReturnUrl } from '@/v1/utils/getReturnUrl';

export default class CheckoutPaymentProcessor extends AbstractPaymentProcessor {
  constructor(cardId, invoice, cvv) {
    super('checkout', cardId, invoice);

    this.cvv = cvv;
  }

  getCvv() {
    return this.cvv;
  }

  pay() {
    return CardPaymentClient.checkoutPaymentCreate({
      cardId: this.getCardId(),
      invoice: this.getInvoice(),
      cvv: this.getCvv(),
      returnUrl: getReturnUrl(),
    }).then(({ data }) => {
      const {
        payment_id: paymentId,
        is_challenge_required: isRedirect,
        challenge_info: challengeInfo,
      } = data;

      const { url: challengeLink } = challengeInfo || {};

      return { isRedirect, challengeLink, paymentId };
    });
  }

  initApplePayPayment(paymentRequest) {
    paymentRequest.invoice = this.getInvoice();
    paymentRequest.cardId = this.getCardId();

    return CardPaymentClient.createCheckoutApplePayPayment(paymentRequest).then(
      ({ data }) => {
        const {
          is_challenge_required: isChallengeRequired,
          challenge_info: challengeInfo,
          payment_id: paymentId,
        } = data;

        return { isChallengeRequired, challengeInfo, paymentId };
      },
    );
  }

  initGooglePayPayment(paymentRequest) {
    paymentRequest.invoice = this.getInvoice();
    paymentRequest.cardId = this.getCardId();

    return CardPaymentClient.createCheckoutGooglePayPayment(
      paymentRequest,
    ).then(({ data }) => {
      const {
        is_challenge_required: isRedirect,
        challenge_info: challengeInfo,
        payment_id: paymentId,
      } = data;

      const { url: challengeLink } = challengeInfo || {};

      return { isRedirect, challengeLink, paymentId };
    });
  }
}
