<template>
  <HiddenTotalFeesDetails
    v-if="isTotalFeesHidden"
    :service-fees="serviceFees"
    :network-fees="networkFees"
    :total-fees="totalFees"
    :is-expanded="isExpanded"
    :is-quote-has-error="isQuoteHasError"
    :is-crypto-fees-enabled="feesInCryptoFeature.isEnabled()"
    @expand="toggleFeesDropdown"
  />

  <RegularFeesDetails
    v-else
    :service-fees="serviceFees"
    :network-fees="networkFees"
    :total-fees="totalFees"
    :is-expanded="isExpanded"
    :is-quote-has-error="isQuoteHasError"
    :is-crypto-fees-enabled="feesInCryptoFeature.isEnabled()"
    @expand="toggleFeesDropdown"
  />
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import RegularFeesDetails from '@/v1/components/FeesDetails/Components/RegularFeesDetails.vue';
import HiddenTotalFeesDetails from '@/v1/components/FeesDetails/Components/HiddenTotalFeesDetails.vue';

export default {
  name: 'FeesDetails',

  components: {
    HiddenTotalFeesDetails,
    RegularFeesDetails,
  },

  props: {
    fees: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const store = useStore();
    const isExpanded = ref(false);

    const toggleFeesDropdown = () => {
      isExpanded.value = !isExpanded.value;
    };

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const feesInCryptoFeature = computed(() => store.getters['feature/feesInCryptoFeature']);
    const isCryptoFeesAvailable = computed(() => {
      return feesInCryptoFeature.value.isEnabled() && store.getters['transaction/fees'].getTotalFee();
    });

    const isTotalFeesHidden = computed(() => store.getters.isCoinmamaTheme);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const currentCurrencyTo = computed(() => {
      let currency = store.getters['widgetQuote/currencyTo'];
      if (!store.getters['widgetQuote/isQuoteReady']) {
        currency = store.getters['exchangeForm/toCurrencyCode'];
      }

      return store.getters['exchangeForm/getCurrencyBy'](currency);
    });
    const paymentOrPayoutMethod = computed(() => store.getters['v2/widgetQuote/quote'](selectedPaymentMethod.value));
    const selectedPaymentMethod = computed(() => {
      if (isSellCryptoFlow.value) {
        return store.getters['v2/paymentMethods/payoutMethod'].paymentMethod;
      }

      return store.getters['v2/paymentMethods/paymentMethod'].paymentMethod;
    });

    const quote = computed(() => {
      const exchangeFormQuote = store.getters['exchangeForm/quote'];
      if (!paymentOrPayoutMethod.value?.amountFrom) {
        return exchangeFormQuote;
      }

      const { currencyCodeTo } = paymentOrPayoutMethod.value;

      return {
        currencyCodeTo,
      };
    });

    const feesCurrency = computed(() => {
      if (props.fees.currency) {
        return props.fees.currency;
      }

      if (isSellCryptoFlow.value) {
        return currentCurrencyTo.value;
      }

      if (feesInCryptoFeature.value.isEnabled()) {
        return store.getters['exchangeForm/getCurrencyBy'](quote.value.currencyCodeTo);
      }
      return store.getters['exchangeForm/fromCurrency'];
    });

    const formatFeeAmount = amount => {
      return parseFloat(Number(amount).toFixed(8));
    };

    const totalFees = computed(() => `${formatFeeAmount(props.fees.total_fee)} ${feesCurrency.value}`);
    const serviceFees = computed(() => `${formatFeeAmount(props.fees.service_fee)} ${feesCurrency.value}`);
    const networkFees = computed(() => {
      if (!isSellCryptoFlow.value && isCryptoFeesAvailable.value) {
        const blockchainFee = formatFeeAmount(props.fees.blockchain_fee);

        if (blockchainFee) {
          if (feesCurrency.value === currentCurrencyTo.value) {
            const networkFee = formatFeeAmount(props.fees.network_fee ?? props.fees.networkFee) + blockchainFee;
            return `${networkFee} ${feesCurrency.value}`;
          }

          return `${formatFeeAmount(props.fees.network_fee ?? props.fees.networkFee)} + ${blockchainFee} ${feesCurrency.value}`;
        }
      }

      return `${formatFeeAmount(props.fees.network_fee ?? props.fees.networkFee)} ${feesCurrency.value}`;
    });

    return {
      isExpanded,
      isQuoteHasError,
      isTotalFeesHidden,

      totalFees,
      serviceFees,
      networkFees,

      toggleFeesDropdown,
      feesInCryptoFeature,
    };
  },
};
</script>
