<template>
  <div class="page-layout">
    <header
      v-if="title"
      :class="{
        'page-layout__header': true,
        'is-clickable': havePreviousStep,
      }"
      data-test="header"
      @click="onHeaderClick"
    >
      <i
        v-if="havePreviousStep"
        class="page-layout__back-icon svg-icon icon-prev"
        data-test="back"
      />

      <div class="page-layout__title">
        {{ title }}
      </div>
    </header>

    <div class="page-layout__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageLayout',

  props: {
    havePreviousStep: {
      type: Boolean,
      default: false,
      required: false,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    headerIcon: {
      type: String,
      required: false,
    },
  },

  emits: ['previous-step'],

  setup(props, { emit }) {
    const onHeaderClick = () => {
      if (!props.havePreviousStep) {
        return;
      }

      emit('previous-step');
    };

    return {
      onHeaderClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-layout {
  padding: 0 rem(16) rem(40) rem(16);
  height: calc(100% - 20px * 2);
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet-max) {
    padding: 0;
    height: 100%;
  }

  @media (min-width: $laptop-min) {
    height: 100%;
    padding-bottom: 0;

    ::v-deep .auth-flow,
    ::v-deep .auth-flow > div {
      height: 100%;
    }

    ::v-deep .auth-flow > div {
      display: flex;
      flex-direction: column;
    }

    ::v-deep .auth-app-footer {
      margin-top: auto;
    }
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    font-size: rem(28);
    font-weight: 700;
    margin-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: $tablet-max) {
      font-size: 24px;
      line-height: 26px;
      margin-top: 8px;
      padding-right: 30px;
      padding-left: 30px;
    }

    @media (max-width: $screen-sm-min) {
      font-size: 20px;
      line-height: 26px;
    }

    &.is-clickable:hover:not(.disabled) {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  &__title {
    min-height: 32px;
    text-align: center;
  }

  &__back-icon {
    width: rem(32);
    height: rem(32);
    position: absolute;
    left: -8px;
    top: 0;
  }

  &__content {
    height: 100%;
  }

  @media (max-width: $screen-sm-min) and (max-height: $widget-height-xxlg) {
    &__title {
      padding-top: 5px;
    }
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &__header {
      font-size: 3vh;
      line-height: 4vh;
    }
  }

  @media (min-width: $laptop-middle) and (max-height: $widget-height-sm) {
    padding-bottom: 3.125vh;

    &__header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
      padding-left: calcVh(32, $widget-h-sm);
      padding-right: calcVh(32, $widget-h-sm);
    }

    &__back-icon {
      width: calcVh(32, $widget-h-sm);
      height: calcVh(32, $widget-h-sm);
      left: calcVh(-8, $widget-h-sm);
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    padding-bottom: 3.13vh;

    &__header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
      padding-left: calcVh(32, $widget-h-xlg);
      padding-right: calcVh(32, $widget-h-xlg);
    }

    &__back-icon {
      width: calcVh(32, $widget-h-xlg);
      height: calcVh(32, $widget-h-xlg);
      left: calcVh(-8, $widget-h-xlg);
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    padding-bottom: 3.18vh;

    &__header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
      padding-left: calcVh(32, $widget-h-xxlg);
      padding-right: calcVh(32, $widget-h-xxlg);
    }

    &__back-icon {
      width: calcVh(32, $widget-h-xxlg);
      height: calcVh(32, $widget-h-xxlg);
      left: calcVh(-8, $widget-h-xxlg);
    }
  }
}
</style>
