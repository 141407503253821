import Api from '../api';

const apiUrl = `${import.meta.env.VITE_APP_CARD_ENTRY_URL}/public/sumsub-service/v1`; // eslint-disable-line no-undef

export default {
  registerVerification(applicantParams) {
    const url = `${apiUrl}/card`;
    return Api().post(url, applicantParams);
  },
  cancelVerification({ cardId }) {
    const url = `${apiUrl}/card/${cardId}/cancel`;
    return Api().post(url);
  },
};
