// Safari now suddenly blocks iframe cookie access, so we need to call this during some user interaction
export const isSafari = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const { userAgent } = window.navigator;

  return userAgent.includes('Safari') && !userAgent.includes('Chrome');
};

export const requestStorageAccess = callback => new Promise((resolve, reject) => {
  if (!isSafari()) {
    return resolve(callback());
  }

  document.requestStorageAccess().then(
    () => {
      resolve(callback());
    },
    () => {
      reject();
    },
  );
});
