export default {
  TYPE_BANK: 'bank',
  TYPE_BANK_SWIFT: 'bank_swift',
  TYPE_CRYPTO: 'crypto',
  TYPE_ELECTRONIC: 'electronic',
  TYPE_GIFT_CARD: 'gift-card',
  TYPE_BANK_CARD: 'credit-card',
  TYPE_CREDIT_CARD: 'cc',
  TYPE_APM: 'apm',
};
