export const NAME = 'exchange-form-preloader';

export default {
  path: '/exchange-form-preloader',
  name: NAME,
  component: () => import('@/v1/views/ExchangeFormPreloader'),
  meta: {
    shouldBePreloaded: true,
  },
};
