import { reactive } from 'vue';

export const apmCurrencyIcon = reactive({
  name: undefined,
});

export const updateCurrencyIcon = name => {
  apmCurrencyIcon.name = name;
};

export const initCurrencyIcon = name => {
  if (apmCurrencyIcon.name) {
    return;
  }

  apmCurrencyIcon.name = name;
};
