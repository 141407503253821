// eslint-disable-next-line import/no-cycle
import widgetApiClient from '@/v1/services/widget/widgetService.v2';

export default {
  namespaced: true,

  state: {
    isPaymentInitiating: false,
  },

  getters: {
    isPaymentInitiating: state => state.isPaymentInitiating,
  },

  mutations: {
    setIsPaymentInitiating(state, payload) {
      state.isPaymentInitiating = payload;
    },
  },

  actions: {
    async initiateFrictionlessPayment({ commit, getters }, requestId) {
      try {
        commit('setIsPaymentInitiating', true);
        return await widgetApiClient.initiateFrictionlessPayment(requestId);
      } catch {
        // XXX: suppress error, we don't need to reset initiating flag
        // (widget should be closed by partner)
      }
    },
  },
};
