import { TRANSACTION_STATUSES } from '@/constants/transactionStatuses';

const TRANSACTION_STATUSES_TO_UI_NAME = {
  [TRANSACTION_STATUSES.STATUS_COMPLETED]: 'widget.transaction.history.completed',
  [TRANSACTION_STATUSES.STATUS_CANCELLED]: 'widget.transaction.history.canceled',
  [TRANSACTION_STATUSES.STATUS_REJECTED]: 'widget.transaction.history.rejected',
  [TRANSACTION_STATUSES.STATUS_PAYOUT_DETAILS]: 'widget.transaction.history.enter-your-wallet',
  [TRANSACTION_STATUSES.STATUS_PAYMENT_DETAILS]: 'widget.transaction.history.make-a-payment',
  [TRANSACTION_STATUSES.STATUS_PAYMENT_WAITING]: 'widget.transaction.history.make-a-payment',
  [TRANSACTION_STATUSES.STATUS_PAYMENT_REVIEW]: 'widget.transaction.history.make-a-payment',
  [TRANSACTION_STATUSES.STATUS_PAYOUT_WAITING]: 'widget.transaction.history.sending-your-crypto',
  [TRANSACTION_STATUSES.STATUS_VERIFICATION]: 'widget.transaction.history.complete-verification',
};

const TRANSACTION_SELL_FLOW_STATUSES_TO_UI_NAME = {
  ...TRANSACTION_STATUSES_TO_UI_NAME,

  [TRANSACTION_STATUSES.STATUS_PAYOUT_WAITING]: 'widget.transaction.history.sell.sending-your-fiat',
  [TRANSACTION_STATUSES.STATUS_PAYOUT_DETAILS]: 'widget.transaction.history.sell.add-payout-details',
  [TRANSACTION_STATUSES.STATUS_PAYMENT_CONFIRMATION_WAITING]: 'widget.transaction.history.sell.payment-confirmation-waiting',
};

export const TRANSACTION_STATUSES_TO_ICONS_MAP = {
  [TRANSACTION_STATUSES.STATUS_COMPLETED]: 'checkbox-green',
  [TRANSACTION_STATUSES.STATUS_CANCELLED]: 'cancelled',
  [TRANSACTION_STATUSES.STATUS_REJECTED]: 'close-red',
};

export const TRANSACTION_STATUSES_I18N_BY_FLOW = {
  FIAT_CRYPTO: TRANSACTION_STATUSES_TO_UI_NAME,
  CRYPTO_FIAT: TRANSACTION_SELL_FLOW_STATUSES_TO_UI_NAME,
};
