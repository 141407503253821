import { StorageService } from '@/services/storageService';

const STORAGE_KEY = 'x-user-locale';

export default {
  storeLocale(locale) {
    StorageService.set(STORAGE_KEY, locale);
  },

  getLocale() {
    return StorageService.get(STORAGE_KEY);
  },
};
