export default class ApmPayment {
  constructor({ id = null, invoice = null } = {}) {
    this.id = id;
    this.invoive = invoice;
  }

  isExist() {
    return !!this.id;
  }
}
