import { CONSTANTS, TokenService, networkClient as authNetworkClient } from './exports';
import eventBus from '@/helpers/eventBus';
import UserLocaleStorage from '@/v1/packages/authentication/services/user-locale-storage';

import { ERROR_CODES } from '@/constants/errorCodes';

const refreshTokenPath = '/public/authentication-service/v1/jwt/refresh';

function getNewToken() {
  const refreshToken = TokenService.getRefreshToken();

  if (refreshToken === undefined) {
    eventBus.emit(CONSTANTS.MISSING_REFRESH_TOKEN_EVENT);
    return Promise.reject(new Error('Missing refresh token'));
  }

  try {
    return authNetworkClient.post(refreshTokenPath, { refreshToken }, {
      headers: {
        'X-User-Language': UserLocaleStorage.getLocale() || 'en',
      },
    });
  } catch (error) {
    const { response } = error;

    if (response === undefined) {
      return Promise.reject(error);
    }

    if ([400, 401, 403].includes(response.status)) {
      eventBus.emit(CONSTANTS.SESSION_TIMEOUT_EVENT);
    }
    return Promise.reject(error);
  }
}

export async function refreshToken() {
  const { data: newTokens } = await getNewToken();

  TokenService.setTokens(newTokens);
}

export async function refreshExpiredToken(requestError) {
  const { response: { status, code } = {}, config } = requestError;

  if (status === 422) {
    return Promise.reject(requestError);
  }

  if (![401, 403].includes(status)) {
    eventBus.emit(CONSTANTS.SESSION_TIMEOUT_EVENT);
    return Promise.reject(requestError);
  }

  if (status === 403 && code === ERROR_CODES.USER_BLOCKED) {
    // XXX: banned user
    eventBus.emit(CONSTANTS.USER_BANNED_EVENT);
    return Promise.reject(requestError);
  }

  const isTokenRefreshRequest = config.url === refreshTokenPath;

  if (status === 403 || (isTokenRefreshRequest && status >= 400)) {
    // XXX: logout user
    eventBus.emit(CONSTANTS.SESSION_TIMEOUT_EVENT);
    return Promise.reject(requestError);
  }

  await refreshToken();

  config.headers.Authorization = `Bearer ${TokenService.getToken()}`;

  return this.request(config);
}
