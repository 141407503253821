<template>
  <div
    ref="wrapper"
    :class="{
      'is-open': isOpen,
      'is-left': left,
    }"
    class="tooltip"
    @mouseenter="handleHover"
    @mouseleave="handleHoverOut"
    @touchstart="handleTouch"
  >
    <span class="tooltip__icon-wrapper">
      <i
        v-if="!hasToggle"
        :class="`svg-icon icon-${icon} tooltip__icon`"
      />

      <slot name="toggle" />
    </span>

    <div
      ref="tooltip"
      class="tooltip__content-wrapper"
    >
      <div class="tooltip__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, defineComponent } from 'vue';
// TODO: remove it and use third party.
export default defineComponent({
  name: 'Tooltip',

  props: {
    left: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: 'question-yellow',
    },
  },

  setup(props, { slots }) {
    const wrapper = ref();
    const tooltip = ref();

    const isOpen = ref(false);
    const scrollableParent = ref();
    const left = ref(props.left);

    const hasToggle = computed(() => !!slots.toggle);

    const handleHover = () => {
      isOpen.value = true;

      if (!wrapper.value) {
        return;
      }

      const leftSpace = wrapper.value.getBoundingClientRect().x;
      const rightSpace = window.innerWidth
        - wrapper.value.getBoundingClientRect().x
        - wrapper.value.getBoundingClientRect().width;

      positionStatic(leftSpace, rightSpace);
    };

    const positionStatic = (leftSpace, rightSpace) => {
      const offset = 260;

      if (tooltip.value) {
        if (leftSpace < offset) {
          if (leftSpace > rightSpace) {
            tooltip.value.setAttribute(
              'style',
              `right: -${rightSpace - 24}px; left: auto`,
            );
          } else {
            tooltip.value.setAttribute(
              'style',
              `left: -${leftSpace - 24}px; right: auto`,
            );
          }
        } else {
          tooltip.value.removeAttribute('style');
        }
      }
    };

    const handleHoverOut = () => {
      isOpen.value = false;

      if (scrollableParent.value) {
        scrollableParent.value.removeEventListener('scroll', handleHoverOut);
      }
    };

    const handleTouch = () => {
      if (!isOpen.value) {
        handleHover();

        document.addEventListener('touchstart', handleCloseAll);
      } else {
        handleHoverOut();
      }
    };

    const handleCloseAll = event => {
      const { target } = event;

      if (
        wrapper.value !== target
        && target instanceof Node
        && !wrapper?.value?.contains(target)
      ) {
        isOpen.value = false;

        document.removeEventListener('click', handleCloseAll);
      }
    };

    onMounted(() => {
      if (props.left && document.dir === 'rtl') {
        // TODO
        // eslint-disable-next-line vue/no-mutating-props
        left.value = false;
      }
    });

    onUnmounted(() => {
      document.removeEventListener('touchstart', handleCloseAll);

      window.removeEventListener('scroll', handleHoverOut);

      if (scrollableParent.value) {
        scrollableParent.value.removeEventListener('scroll', handleHoverOut);
      }
    });

    return {
      wrapper,
      tooltip,

      isOpen,
      handleHover,
      handleHoverOut,
      handleTouch,
      hasToggle,
    };
  },
});
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
  line-height: inherit;
  vertical-align: text-bottom;
  cursor: help;

  &__content-wrapper {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 0;
    right: auto;
    z-index: 990;
    padding: rem(6) 0;
    opacity: 0;
    transform: translate3d(0, #{rem(4)}, 0);
    visibility: hidden;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0.2s;
  }

  [dir='rtl'] &__content-wrapper {
    right: 0;
    left: unset;
  }

  &.is-bottom &__content-wrapper {
    top: 100%;
    bottom: auto;
    transform: translate3d(0, #{rem(-4)}, 0);
  }

  &.is-left &__content-wrapper {
    right: 0;
    left: auto;
  }

  [dir='rtl'] &.is-left &__content-wrapper {
    left: 0;
    right: auto;
  }

  &.is-fixed &__content-wrapper {
    position: fixed;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  &.is-open &__content-wrapper {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0s;
  }

  &__content {
    position: relative;
    background: $black;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: rem(6);
    padding: rem(8);
    color: $white;
    width: rem(224);
    max-width: 80vw;
    font-size: rem(12);
    line-height: 1.33;
    text-align: left;
  }

  &.auto-width &__content {
    width: auto;
  }

  &__content a {
    border-bottom: 1px solid rgba($white, 0.3);
    transition: border-color 0.2s linear;
  }

  &__content a:hover {
    border-color: $black-06;
  }

  &__icon-wrapper {
    display: flex;
  }

  &__icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  @media (min-width: $desktop-xlg-min) {
    &__icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &__icon {
      width: calcVh(18, $widget-h-sm);
      height: calcVh(18, $widget-h-sm);
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    &__icon {
      width: calcVh(20, $widget-h-xlg);
      height: calcVh(20, $widget-h-xlg);
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    &__icon {
      width: calcVh(20, $widget-h-xxlg);
      height: calcVh(20, $widget-h-xxlg);
    }
  }
}
</style>
