import { isNewExchangeFormFlowEnabled } from '@/v1/services/exchangeFormFlow';

export const NAME = 'v2-exchange-form';

export default {
  path: '/v2/exchange-form',
  name: NAME,
  component: () => import('@/v1/views/v2/ExchangeFormScreen'),
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
