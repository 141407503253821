<template>
  <div class="transaction-history-item-flow">
    <img
      :src="flowIcon"
      alt="Transaction flow"
      class="transaction-history-item-flow__icon"
    >
    <span class="transaction-history-item-flow__text">{{ flow }}</span>
  </div>
</template>

<script setup>
  import buy from '@/v1/assets/images/widget/buy.svg?url';
  import sell from '@/v1/assets/images/widget/sell.svg?url';
  import { computed, defineProps } from 'vue';

  const props = defineProps({
    flow: {
      type: String,
      required: true,
    },
  });

  const flowIcon = computed(() => {
    return props.flow === 'CRYPTO_FIAT' ? sell : buy;
  });

  const flow = computed(() => {
    return props.flow === 'CRYPTO_FIAT' ? 'Sell' : 'Buy';
  });
</script>

<style lang="scss" scoped>
  .transaction-history-item-flow {
    display: flex;

    @media (min-width: $screen-sm-min) {
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      gap: 2px;
      background-color: $grey-04;
      width: 43px;
      height: 24px;
    }

    &__text {
      display: none;
      font-weight: 400;

      @media (min-width: $screen-sm-min) {
        font-size: 12px;
        display: inline;
      }
    }

    &__icon {
      width: 14px;
      height: 14px;

      @media (min-width: $screen-sm-min) {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
