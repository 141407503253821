<template>
  <div
    class="widget-wrapper"
    :class="{
      'widget-hidden': !isAnyRouteLoaded,
      'widget-wrapper--animated': isAnyRouteLoaded,
      'widget-wrapper--light': lightLayout,
    }"
  >
    <template v-if="isUKUser">
      <FCANotificationBanner
        fixed
        @show-modal="showFCANotificationPopupModal"
      />
      <FCANotificationBanner />
    </template>
    <component
      :is="currentComponent"
      @closeWidget="toggleCloseConfirmModal"
    />
    <Modal
      :show="isCloseConfirmVisible"
      :component="$options.modals.CloseModal"
      @confirm="closeWidget"
      @reject="toggleCloseConfirmModal"
    />

    <Modal
      :show="isConfirmationVisible"
      :component="$options.modals.ConfirmationModal"
      @confirm="handleBackButton"
      @reject="toggleConfirmationModal"
    />

    <Modal
      :show="isApplePayNotificationVisible"
      :component="$options.modals.ApplePayNotificationModal"
      @confirm="closeWidget"
      @reject="toggleApplePayNotificationModal"
    />

    <Modal
      :show="isFCANotificationPopup"
      :component="$options.modals.FCANotificationPopup"
      :is-without-blur="true"
      @reject="hideFCANotificationPopupModal"
    />

    <Modal
      :show="isTransactionCryptocurrencyIsNotAvailableModalVisible"
      :component="$options.modals.TransactionCryptocurrencyIsNotAvailableModal"
      @confirm="closeWidget"
    />

    <Modal
      :show="isTransactionValidationErrorModalVisible"
      :component="$options.modals.TransactionValidationErrorModal"
      @confirm="closeWidget"
    />

    <Modal
      :show="isCryptocurrencyIsNotAvailableModalVisible"
      :component="$options.modals.CryptocurrencyIsNotAvailableModal"
      @confirm="closeWidget"
    />

    <Modal
      :show="isCoolingOffUserModalVisible"
      :component="$options.modals.CoolingOffUserModal"
      @close="hideCoolingOffUserPopupModal"
    />

    <Modal
      :show="isBannedUserModalVisible"
      :component="$options.modals.BannedUserModal"
      @confirm="closeWidget"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import WidgetFooter from '@/v1/components/WidgetFooter';
import WidgetContentArea from '@/v1/components/WidgetContentArea';
import WidgetPartnerInfo from '@/v1/components/WidgetPartnerInfo';
import WidgetHeader from '@/v1/components/WidgetHeader';
import FCANotificationBanner from '@/v1/components/WidgetFCANotificationBanner';

import {
  Modal,
  CloseModal,
  ConfirmationModal,
  FCANotificationPopup,
  TransactionCryptocurrencyIsNotAvailableModal,
  TransactionValidationErrorModal,
  CryptocurrencyIsNotAvailableModal,
  CoolingOffUserModal,
  BannedUserModal,
  ApplePayNotificationModal,
} from '@/v1/components/Modal';

import { emitToParent, STATES } from '@/services/widgetApiEvents';
import { onBackButtonEvent } from '@/v1/composables/backButtonEvents';
import {
  shouldChangeAppearanceForError as showLeftSidebar,
  isErrorRoute as hideTransactionDetails,
  widgetWrapperAlignment,
} from '@/v1/composables/errorScreenAppearance';

import { canClose } from '@/services/widgetSettings';
import { isApplePayOneClickFlow } from '@/v1/services/applePayOneClickService';
import { Layouts } from '@/constants/layouts';
import LightLayout from '@/v1/layouts/Light.vue';
import DefaultLayout from '@/v1/layouts/Default.vue';
import getParams from '@/v1/utils/getParams';
import { useRoutePreloader } from '@/v1/router/PreloadRouterView';
import { useBackButton } from '@/v1/composables/useBackButton';

export default {
  name: 'WidgetWrapper',

  modals: {
    CloseModal,
    ConfirmationModal,
    BannedUserModal,
    TransactionCryptocurrencyIsNotAvailableModal,
    TransactionValidationErrorModal,
    CryptocurrencyIsNotAvailableModal,
    CoolingOffUserModal,
    ApplePayNotificationModal,
    FCANotificationPopup,
  },

  components: {
    WidgetFooter,
    WidgetHeader,
    FCANotificationBanner,
    WidgetContentArea,
    WidgetPartnerInfo,

    Modal,
  },
  setup() {
    const store = useStore();
    const { handleBackButton, isConfirmationVisible, toggleConfirmationModal } = useBackButton();

    const isCloseConfirmVisible = ref(false);

    const isTransactionCryptocurrencyIsNotAvailableModalVisible = computed(
      () => store.getters.showTransactionCryptocurrencyIsNotAvailablePopup,
    );
    const isTransactionValidationErrorModalVisible = computed(
      () => store.getters.showTransactionValidationErrorPopup,
    );
    const isCryptocurrencyIsNotAvailableModalVisible = computed(
      () => store.getters.showCryptocurrencyIsNotAvailablePopup,
    );
    const isCoolingOffUserModalVisible = computed(
      () => store.getters.showCoolingOffUserPopup,
    );
    const isBannedUserModalVisible = computed(
      () => store.getters.showBannedUserPopup,
    );
    const isFCANotificationPopup = computed(
      () => store.getters.showFCANotificationPopup,
    );
    const isApplePayNotificationVisible = computed(
      () => store.getters.showApplePayNotificationPopup && !isApplePayOneClickFlow(),
    );

    const toggleCloseConfirmModal = () => {
      isCloseConfirmVisible.value = !isCloseConfirmVisible.value;
    };
    const toggleApplePayNotificationModal = () => {
      store.dispatch('hideApplePayNotificationPopup');
    };
    const hideFCANotificationPopupModal = () => {
      store.dispatch('hideFCANotificationPopup');
      document.body.style.overflow = '';
    };
    const hideCoolingOffUserPopupModal = () => {
      store.dispatch('hideCoolingOffUserPopup');
      emitToParent('state', {
        state: STATES.STATE_CLOSED,
      });
    };

    const closeWidget = () => {
      toggleCloseConfirmModal();
      emitToParent('state', {
        state: STATES.STATE_CLOSED,
      });
    };

    onBackButtonEvent(() => {
      if (!canClose()) {
        return;
      }
      if (!isCloseConfirmVisible.value) {
        toggleCloseConfirmModal();
      }
    });

    const isUKUser = computed(() => store.getters.isUkCustomer);
    const { layout } = getParams();
    const lightLayout = computed(() => layout === Layouts.LIGHT);
    const currentComponent = computed(() => (layout === Layouts.LIGHT ? LightLayout : DefaultLayout));
    const showFCANotificationPopupModal = () => {
      store.dispatch('showFCANotificationPopup');
      document.body.style.overflow = 'hidden';
    };

    const { loadedRoutes } = useRoutePreloader();

    const isCoinmamaTheme = computed(() => store.getters.themeConfig?.serviceName === 'Coinmama');

    const isAnyRouteLoaded = computed(
      () => Object.entries(loadedRoutes).filter(
        ([, inLoadedState]) => inLoadedState,
      ).length > 0,
    );

    watch(
      isAnyRouteLoaded,
      newValue => {
        if (newValue) {
          window.dispatchEvent(new Event('pb-widget-loaded'));

          emitToParent('state', {
            state: 'loaded',
          });

          if (isCoinmamaTheme.value) {
            emitToParent('loader-styles-change', {
              backgroundColor: '#eeeeee',
            });
          }
        }
      },
      { immediate: true },
    );

    return {
      isCloseConfirmVisible,
      isConfirmationVisible,
      isApplePayNotificationVisible,
      isFCANotificationPopup,
      toggleCloseConfirmModal,
      closeWidget,
      toggleConfirmationModal,
      handleBackButton,

      isAnyRouteLoaded,
      currentComponent,
      lightLayout,

      isTransactionCryptocurrencyIsNotAvailableModalVisible,
      isTransactionValidationErrorModalVisible,
      isCryptocurrencyIsNotAvailableModalVisible,
      isCoolingOffUserModalVisible,
      isBannedUserModalVisible,
      isUKUser,

      toggleApplePayNotificationModal,
      hideFCANotificationPopupModal,
      showFCANotificationPopupModal,
      hideCoolingOffUserPopupModal,

      hideTransactionDetails,
      showLeftSidebar,
      widgetWrapperAlignment,
    };
  },
};
</script>
<style lang="scss" scoped>
$simpleFadeBg: simpleFadeBg 1s both ease-in-out;

.widget-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 320px;
  min-height: 100vh;
  position: relative;
  background: none;
  z-index: 1;
  transition: opacity 0.1s linear;
  overflow: hidden;

  @media (max-width: 450px) {
    min-height: -webkit-fill-available;
  }

  &.widget-hidden {
    opacity: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: all .3s ease-in-out;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: none;
    z-index: -2;
  }
  &--light {
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    grid-template-columns: 100%;
    min-height: 100vh;

    background: black !important;
  }
  // bg animation
  &--animated {
    &::before, &::after {
      animation: $simpleFadeBg;
    }
  }
}

//// animation
@keyframes simpleFadeBg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
