import Api from '../api';
import UrlGenerator from '../url';

export default {
  acceptCookie() {
    return Api().post('/hide-cookie-popup/', null, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  },
  getQuote(searchParams) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return Api().get(`/transaction/quote/?${urlSearchParams.toString()}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  },
  // Transaction flow
  createTransactionPromo({ requestParams, language }) {
    return Api().post(
      UrlGenerator.generate('/api/transaction/create/promo/'),
      requestParams,
      {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Accept-Language': language,
        },
      },
    );
  },
  repeatTransactionByInvoice(invoice) {
    const url = UrlGenerator.generate(`/api/transaction/${invoice}/repeat/`);
    return Api().get(url);
  },

  // Transaction filters
  getFilters() {
    return Api()
      .get(UrlGenerator.generate('/member/transactions/filter/'))
      .then(response => response.data || {})
      .then(data => {
        const { transactionStatuses, transactionMoneyServices } = data;

        return {
          statuses: transactionStatuses || {},
          moneyServices: transactionMoneyServices || {},
        };
      });
  },
  // Transaction list
  getTransactions(TransactionFilterBuilder, { page, perPage }) {
    if (!page) {
      return Promise.reject(
        new Error('Invalid request: initial page is unset.'),
      );
    }

    if (!perPage) {
      return Promise.reject(
        new Error('Invalid request: items per page is not configured.'),
      );
    }

    return Api()
      .get(UrlGenerator.generate('/member/transactions/search/'), {
        params: {
          ...TransactionFilterBuilder.getFormatted(),
          page,
          limit: perPage,
        },
      })
      .then(response => response.data || {})
      .then(data => ({
        transactions: data.transactions || [],
        paginator: {
          page: data.page || page,
          pageSize: data.pageSize || perPage,
          total: data.total || 0,
        },
      }));
  },
  checkoutTransactionByInvoice(invoice) {
    const url = UrlGenerator.generate(`/api/transaction/${invoice}/checkout/`);
    return Api().put(url);
  },
};
