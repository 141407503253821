import {
  VerificationStatus,
  VerificationStatusLegacy,
} from './verification/status';

export default class Verification {
  constructor(status = null) {
    this.status = status;
  }

  isUnverified() {
    return this.status === VerificationStatusLegacy.STATUS_UNVERIFIED;
  }

  isVerified() {
    return this.status === VerificationStatusLegacy.STATUS_VERIFIED;
  }

  isRejected() {
    return this.status === VerificationStatusLegacy.STATUS_REJECTED;
  }

  isUnderReview() {
    return this.status === VerificationStatusLegacy.STATUS_UNDER_REVIEW;
  }

  getStatusSlug() {
    if (this.isUnverified()) {
      return VerificationStatus.STATUS_PENDING;
    }
    if (this.isVerified()) {
      return VerificationStatus.STATUS_APPROVED;
    }
    if (this.isRejected()) {
      return VerificationStatus.STATUS_REJECTED;
    }
    if (this.isUnderReview()) {
      return VerificationStatus.STATUS_REVIEW;
    }
    return '';
  }

  unverify() {
    this.status = VerificationStatusLegacy.STATUS_UNVERIFIED;
  }

  verify() {
    this.status = VerificationStatusLegacy.STATUS_VERIFIED;
  }

  review() {
    this.status = VerificationStatusLegacy.STATUS_UNDER_REVIEW;
  }

  reject() {
    this.status = VerificationStatusLegacy.STATUS_REJECTED;
  }
}
