import TokenService from '../../services/token';
import { MISSING_REFRESH_TOKEN_EVENT, USER_BANNED_EVENT, SESSION_TIMEOUT_EVENT } from '../../constants';

import eventBus from '../../helpers/eventBus';
import UserLocaleStorage from '../../services/user-locale-storage';

const refreshTokenPath = '/public/authentication-service/v1/jwt/refresh';

function getNewToken(client) {
  const refreshToken = TokenService.getRefreshToken();

  if (refreshToken === undefined) {
    eventBus.emit(MISSING_REFRESH_TOKEN_EVENT);
    return Promise.reject(new Error('Missing refresh token'));
  }

  try {
    return client.post(refreshTokenPath, { refreshToken }, {
      headers: {
        'X-User-Language': UserLocaleStorage.getLocale() || 'en',
      },
    });
  } catch (error) {
    const { response } = error;

    if (response === undefined) {
      return Promise.reject(error);
    }

    if ([400, 401, 403].includes(response.status)) {
      eventBus.emit(SESSION_TIMEOUT_EVENT);
    }
    return Promise.reject(error);
  }
}

export function addAuthHeader(config) {
  if (!config.skipAuthHeader && TokenService.hasToken()) {
    config.headers.Authorization = `Bearer ${TokenService.getToken()}`;
  }
  return config;
}

export async function refreshExpiredToken(requestError) {
  const { response, config } = requestError;
  if (response === undefined) {
    return Promise.reject(requestError);
  }
  const { status, data: { code } } = response;
  const isTokenRefreshRequest = config.url === refreshTokenPath;

  if (isTokenRefreshRequest && status === 400) {
    eventBus.emit(SESSION_TIMEOUT_EVENT);
    return Promise.reject(requestError);
  }

  if (![401, 403].includes(status)) {
    return Promise.reject(requestError);
  }

  if (status === 403) {
    if (isTokenRefreshRequest) {
      eventBus.emit(SESSION_TIMEOUT_EVENT);
    }
    if (code === 'user-blocked') {
      eventBus.emit(USER_BANNED_EVENT);
    }
    return Promise.reject(requestError);
  }

  const newTokens = await getNewToken(this);

  TokenService.setTokens(newTokens);
  config.headers.Authorization = `Bearer ${newTokens.jwt}`;

  return this.request(config);
}
