export const getTimerFromTimestamp = timestamp => {
  const secondsToResend = new Date(timestamp);
  if ((timestamp / 1000) < 1) {
    return '0:00';
  }
  const minutes = secondsToResend.getMinutes();
  const seconds = secondsToResend.getSeconds();

  return `${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
};
