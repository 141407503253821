import { captureException } from '../plugins/sentry';

const PLUGIN_URL = 'https://bp-checkout-v2.paybis.com/v2/loader';
const DEFAULT_CONFIG = {
  cashierKey: '',
  cashierToken: '',
  directPaymentMethod: '',
  singlePaymentMethod: 'apm',
  singlePaymentProvider: '',
  dontSkipSinglePaymentBox: '',
  theme: 'transparent',
  buttonMode: 'spot',
  buttonText: 'Buy now',
  hideLanguagesDropdown: true,
};

export default {
  _normalizeConfig(config = {}) {
    // Order is important
    return {
      ...DEFAULT_CONFIG,
      ...config,
    };
  },

  _createScript(config) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = PLUGIN_URL;
      script.onload = resolve;
      script.onerror = reject;

      script.setAttribute('data-cashier-key', config.cashierKey);
      script.setAttribute('data-cashier-token', config.cashierToken);

      script.setAttribute(
        'data-direct-payment-method',
        config.directPaymentMethod,
      );
      script.setAttribute(
        'data-single-payment-method',
        config.singlePaymentMethod,
      );
      script.setAttribute(
        'data-single-payment-provider',
        config.singlePaymentProvider,
      );
      script.setAttribute(
        'data-dont-skip-single-payment-box',
        config.dontSkipSinglePaymentBox,
      );

      script.setAttribute('data-theme', config.theme);
      script.setAttribute('data-button-mode', config.buttonMode);
      script.setAttribute('data-button-text', config.buttonText);
      script.setAttribute(
        'data-hide-languages-dropdown',
        config.hideLanguagesDropdown,
      );

      document.querySelector('.apm-payment-waiting')?.replaceWith(script);
    });
  },

  _initEventListener() {
    window.addEventListener('message', event => {
      if (!event.data.event || !event.data.event.includes('[bp]')) {
        return;
      }

      if (event.data.event === '[bp]:redirect') {
        window.location.href = event.data.url;
      }

      switch (event.data.event) {
        case '[bp]:create-session':
        case '[bp]:contentRendered':
        case '[bp]:deposit':
        case '[bp]:redirect':
          break;
      }
    });
  },

  init(config = {}) {
    this._initEventListener();
    return this._createScript(this._normalizeConfig(config));
  },
};
