<template>
  <div
    class="fca-notification-banner"
    :class="{
      'fca-notification-banner--fixed': props.fixed,
    }"
  >
    <i18n-t
      keypath="fca-notification.banner.paragraph"
      tag="span"
    >
      <template #link>
        <a
          class="fca-notification-banner__link"
          @click="$emit('show-modal')"
        >
          {{ t('fca-notification.banner.paragraph.link') }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  fixed: { type: Boolean, default: false },
});
</script>

<style lang="scss" scoped>
.fca-notification-banner {
  width: 100%;
  min-height: 68px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;

  background-color: #f1f2fb;
  font-weight: bold;
  text-align: center;
  line-height: 24px;

  z-index: 6;

  @media (max-width: $tablet-max) {
    text-align: start;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  &:not(&--fixed) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__link {
    border-bottom: 2px solid;
    cursor: pointer;
    text-decoration: none;
  }
}
</style>
