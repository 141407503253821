export const NAME = 'transaction';

export default {
  path: '/transaction',
  name: NAME,
  component: () => import('@/v1/views/TransactionFlowScreen'),
  meta: {
    shouldBePreloaded: true,
    requiresAuth: true,
  },
};
