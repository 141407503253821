import { defineComponent, h } from 'vue';
import { useStore } from 'vuex';
import { getCurrentComponent } from './StaticPageLayout.utils';

export default defineComponent({
  name: 'StaticPageLayoutContainer',

  props: {
    currentItem: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const store = useStore();

    const onPreviousStep = () => store.dispatch('selectMenuItem', {
      item: null,
    });
    const Component = getCurrentComponent(props.currentItem);

    return () => h(Component, {
      onPreviousStep,
    });
  },
});
