import { VerificationStatus } from './status';

export default class BasicVerification {
  constructor(status = null) {
    this.status = status;
  }

  isNone() {
    return this.status === VerificationStatus.STATUS_NONE;
  }

  isPending() {
    return this.status === VerificationStatus.STATUS_PENDING;
  }

  isApproved() {
    return this.status === VerificationStatus.STATUS_APPROVED;
  }
}
