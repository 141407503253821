import {
  AUTHENTICATED_EVENT,
  LOADED_EVENT,
  ERROR_EVENT,
  USER_BANNED_EVENT,
  MISSING_REFRESH_TOKEN_EVENT,
  SESSION_TIMEOUT_EVENT,
} from '@/v1/packages/authentication/constants';

import '@/v1/packages/authentication/assets/scss/main.scss';

export { default as TokenService } from '@/v1/packages/authentication/services/token';
export { refreshExpiredToken, addAuthHeader } from '@/v1/packages/authentication/network/interceptors/authInterceptors';
export { default as networkClient } from '@/v1/packages/authentication/network/clients/authentication';
export { default as authService } from '@/v1/packages/authentication/services/auth';
export { default } from '@/v1/packages/authentication/App.vue';

// XXX: this component should be moved into `@/v1/components` after refactoring of auth-app
export { default as HeaderTitle } from '@/v1/packages/authentication/components/HeaderTitle';

export const CONSTANTS = {
  AUTHENTICATED_EVENT,
  LOADED_EVENT,
  ERROR_EVENT,
  USER_BANNED_EVENT,
  MISSING_REFRESH_TOKEN_EVENT,
  SESSION_TIMEOUT_EVENT,
};
