import { ref } from 'vue';
import getParams from '@/v1/utils/getParams';

const isNewExchangeFormFeatureFlagEnabled = !!import.meta.env.VITE_APP_NEW_EXCHANGE_FORM_FLOW;

const isNewExchangeFormFlow = ref(null);

export const isNewExchangeFormFlowEnabled = () => {
  const { switchExchangeFormFlow } = getParams();
  if (isNewExchangeFormFlow.value === null) {
    isNewExchangeFormFlow.value = switchExchangeFormFlow
      ? !isNewExchangeFormFeatureFlagEnabled
      : isNewExchangeFormFeatureFlagEnabled;
  }

  return isNewExchangeFormFlow.value;
};
