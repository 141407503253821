<template>
  <div class="fca-notification-popup__inner">
    <h1 class="fca-notification-popup__title">
      {{ t('fca-notification.title') }}
    </h1>

    <div
      class="fca-notification-popup__msg"
      v-html="$t('fca-notification.popup.widget-text')"
    />

    <div class="fca-notification-popup__actions">
      <button
        class="btn btn-lg btn-primary"
        @click="$emit('reject')"
      >
        {{ $t('widget.close') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
<style lang="scss">
.fca-notification-popup__text {
  strong {
    font-weight: bold;
  }

  ul {
    list-style: inside disc;

    li {
      margin-bottom: rem(8);
    }
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin: rem(16) 0;
  }

  a {
    border-bottom: 1px solid $black-15;
    transition: border-color 0.2s linear;

    &:hover {
      border-color: $black-60;
    }
  }
}
</style>
<style scoped lang="scss">
.fca-notification-popup {
  &__inner {
    background-color: white;
    max-width: rem(1000);
    padding: rem(34);

    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin-top: rem(18);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    margin-top: rem(4);
    text-align: start;
    font-size: rem(14);
    line-height: rem(18);
    margin: rem(20);
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: circle;
    gap: 10px;
  }

  &__link {
    color: red;
  }
}
</style>
