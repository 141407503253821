import { ref } from 'vue';

const isLoading = ref(false);
let bootInterval;

const SupportChatHelper = {
  load(chatKey) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${chatKey}`;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  },

  open({ chatKey, lang = 'en' }) {
    if (!chatKey) return;
    if (window.zE) {
      window.zE('messenger', 'open');
      return;
    }

    isLoading.value = true;

    this.load(chatKey);

    bootInterval = setInterval(() => {
      if (!window.zE) return;
      isLoading.value = false;
      window.zE('messenger:set', 'locale', lang);
      window.zE('messenger', 'open');
      clearInterval(bootInterval);
    }, 1000);
  },
};

export {
  SupportChatHelper,
  isLoading,
};
