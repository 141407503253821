export class Feature {
  constructor(enabled = false) {
    this.enabled = enabled;
  }

  isEnabled() {
    return this.enabled;
  }
}

export class WithFeature {
  constructor() {
    this.feature = new Feature();
  }

  setupFeature(feature) {
    this.feature = feature;
  }

  getFeature() {
    return this.feature;
  }
}
