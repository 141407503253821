import { TransactionStatus } from './transaction/status';

export default class Transaction {
  constructor(status = null, flags = {}) {
    this.status = status;
    this.setFlags(flags);
  }

  // eslint-disable-next-line camelcase
  setFlags({
    instant_payment,
    oct,
    cancellable,
    manual_payment,
    new_flow,
    card_entry,
  }) {
    // eslint-disable-next-line camelcase
    this.instantPayment = instant_payment;
    this.oct = oct;
    this.cancellable = cancellable;
    // eslint-disable-next-line camelcase
    this.manualPayment = manual_payment;
    // eslint-disable-next-line camelcase
    this.newFlow = new_flow;
    // eslint-disable-next-line camelcase
    this.cardEntry = card_entry;
  }

  // Statuses

  isVerification() {
    return this.status === TransactionStatus.STATUS_VERIFICATION;
  }

  isPaymentDetails() {
    return this.status === TransactionStatus.STATUS_PAYMENT_DETAILS;
  }

  isPayoutWaiting() {
    return this.status === TransactionStatus.STATUS_PAYOUT_WAITING;
  }

  isPaymentWaiting() {
    return this.status === TransactionStatus.STATUS_PAYMENT_WAITING;
  }

  isPaymentReview() {
    return this.status === TransactionStatus.STATUS_PAYMENT_REVIEW;
  }

  isPayoutDetails() {
    return this.status === TransactionStatus.STATUS_PAYOUT_DETAILS;
  }

  isPaymentChecks() {
    return this.status === TransactionStatus.STATUS_PAYMENT_CHECKS;
  }

  isCancelled() {
    return this.status === TransactionStatus.STATUS_CANCELLED;
  }

  isRejected() {
    return this.status === TransactionStatus.STATUS_REJECTED;
  }

  isCompleted() {
    return this.status === TransactionStatus.STATUS_COMPLETED;
  }

  isPayoutAuthorizationPending() {
    return (
      this.status === TransactionStatus.STATUS_PAYOUT_AUTHORIZATION_PENDING
    );
  }

  isPayoutAuthorizationProcessing() {
    return (
      this.status === TransactionStatus.STATUS_PAYOUT_AUTHORIZATION_PROCESSING
    );
  }

  isPaymentError() {
    return this.status === TransactionStatus.STATUS_PAYMENT_ERROR;
  }

  // Flags
  isInstantPayment() {
    return this.instantPayment === true;
  }

  isOct() {
    return this.oct === true;
  }

  isCancellable() {
    return this.cancellable === true;
  }

  isManualPayment() {
    return this.manualPayment === true;
  }

  isNewFLow() {
    return this.newFlow === true;
  }

  isCardEntry() {
    return this.cardEntry === true;
  }
}
