import MoneyService from '../moneyService';
import Bank from './details/bank';
import Crypto from './details/crypto';
import Money from '../money';
import MoneyServiceFlowType from './flowType';

export default class Payment extends MoneyService {
  constructor(
    name = null,
    icon = null,
    slug = null,
    type = null,
    canCheckout = null,
    details = [],
    isRefused = false,
    isError = false,
    iconName = null,
    amount = {},
    flowType = null,
    autoFallbackTimer = null,
    fallbackAvailable = false,
    status = null,
  ) {
    super(name, icon, slug, type, iconName);
    this.canCheckout = canCheckout;
    this.details = details;
    this.amount = new Money(amount.amount, amount.currency);
    this.isRefused = isRefused;
    this.isError = isError;
    this.flowType = flowType;
    this.autoFallbackTimer = autoFallbackTimer;
    this.fallbackAvailable = fallbackAvailable;
    this.status = status;
  }

  getDetails() {
    const type = this.getType();
    const details = this.details[type] || {};

    if (this.isBank() || this.isBankSwift()) {
      return new Bank(details);
    }

    if (this.isCrypto()) {
      return new Crypto(details);
    }

    if (this.isBankCard() || this.isGiftCard()) {
      return {};
    }

    throw new Error(`There is no payment details for payment type: ${type}`);
  }

  checkoutable() {
    // eslint-disable-next-line no-undef
    if (this.isBankCard()) {
      // Card entry: do not checkout when payment service is credit-card
      return false;
    }

    return this.canCheckout === true;
  }

  refused() {
    return this.isRefused === true;
  }

  error() {
    return this.isError === true;
  }

  isFallbackAvailable() {
    return this.fallbackAvailable;
  }

  setFallbackAvailable(value) {
    this.fallbackAvailable = value;
  }

  getAutoFallbackTimer() {
    return this.autoFallbackTimer;
  }

  isTrustly() {
    return this.flowType === MoneyServiceFlowType.TRUSTLY;
  }
}
