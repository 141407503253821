import countryCodes from 'country-codes-list';

const codeList = countryCodes.customList('countryCode', '+{countryCallingCode}');
const mappedCodeList = Object.keys(codeList).map(countryCode => ({
  label: codeList[countryCode],
  code: countryCode,
}));
const countryDialCodes = mappedCodeList.sort((a, b) => {
  const codeA = parseInt(a.label.replace(/\+|\s/g, ''), 10);
  const codeB = parseInt(b.label.replace(/\+|\s/g, ''));
  if (codeA < codeB) {
    return -1;
  }
  if (codeA > codeB) {
    return 1;
  }

  return 0;
});

export { countryDialCodes };
