<template>
  <div
    class="transaction-history-item"
  >
    <div
      class="transaction-history-item__data"
      :class="{ 'transaction-history-item__data--loading': loading }"
    >
      <div class="transaction-history-item__transfer text-bold">
        <TransactionHistoryItemFlow
          class="transaction-history-item__flow"
          :flow="transaction.flow"
        />
        <span>{{ transaction.amountFromFormatted }}</span>
        <i
          class="transaction-history-item__direction svg-icon icon-arrow-long-down-black"
        />
        <span>{{ transaction.amountToFormatted }}</span>
      </div>
      <div class="transaction-history-item__info text-grey">
        <div class="transaction-history-item__invoice">
          {{ transaction.invoice }}
        </div>
      </div>
      <div
        v-if="transactionStatusIcon"
        class="transaction-history-item__status text-bold"
        :class="{
          'transaction-history-item__status-verification': isVerification,//special style for Coinmama theme
          'text-red': transaction.status === TRANSACTION_STATUSES.STATUS_REJECTED,
          'text-green': transaction.status === TRANSACTION_STATUSES.STATUS_COMPLETED
        }"
      >
        <i
          class="transaction-history-item__icon svg-icon"
          :class="`icon-${transactionStatusIcon}`"
        />
        <span class="transaction-history-item__status-name">
          {{ transactionStatusName }}
        </span>
      </div>
    </div>
    <i
      v-if="loading"
      class="transaction-history-item__loader"
    />
    <i
      v-else
      class="transaction-history-item__icon svg-icon icon-next"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import TransactionHistoryItemFlow from './TransactionHistoryItemFlow';
import { TRANSACTION_STATUSES } from '@/constants/transactionStatuses';
import {
  TRANSACTION_STATUSES_TO_ICONS_MAP,
  TRANSACTION_STATUSES_I18N_BY_FLOW,
} from './TransactionHistoryItem.constants';

export default defineComponent({
  name: 'TransactionHistoryItem',

  components: {
    TransactionHistoryItemFlow,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
    selectedInvoice: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const requestLoading = computed(() => store.getters['request/isLoading']);

    const loading = computed(() => {
      const { transaction, selectedInvoice } = props;

      return requestLoading.value && transaction.invoice === selectedInvoice;
    });

    const transactionStatusIcon = computed(
      () => TRANSACTION_STATUSES_TO_ICONS_MAP[props.transaction.status] || 'clock',
    );

    const transactionStatusName = computed(() => {
      const { flow: transactionFlow, status, payment } = props.transaction;

      if (transactionFlow === 'CRYPTO_FIAT' && payment.status === 'waiting-for-confirmation') {
        return t(TRANSACTION_STATUSES_I18N_BY_FLOW[transactionFlow][payment.status]) || '';
      }

      return t(TRANSACTION_STATUSES_I18N_BY_FLOW[transactionFlow][status]) || '';
    });

    const isVerification = computed(() => {
      return props.transaction.status === TRANSACTION_STATUSES.STATUS_VERIFICATION;
    });

    return {
      loading,

      transactionStatusIcon,
      transactionStatusName,
      isVerification,

      TRANSACTION_STATUSES,
    };
  },
});
</script>

<style lang="scss" scoped>
$transaction-icon-frame: 16px;
$transaction-icon-size: 12px;

.transaction-history-item {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: rem(12) 0;
  border-bottom: 1px solid $black-06;
  transition: all ease-in .2s;

  &:hover {
    opacity: 0.6;
  }

  &__data {
    transition: all ease-in .2s;

    &--loading {
      opacity: 0.2;
    }
  }

  &__icon {
    display: inline-block;
    width: $transaction-icon-frame;
    height: $transaction-icon-frame;
    background-repeat: no-repeat;
    background-position: center;
    background-size: $transaction-icon-size $transaction-icon-size;
    margin-top: 2px;

    &.icon-next {
      margin-left: auto;
    }
  }

  &__loader {
    @include spinerIcon;

    width: $transaction-icon-frame;
    height: $transaction-icon-frame;

    &:after {
      width: $transaction-icon-frame;
      height: $transaction-icon-frame;
    }

    @media (max-width: $screen-lg-min) {
      margin-left: auto;
    }
  }

  &__direction {
    display: inline-block;
    vertical-align: middle;
    width: $transaction-icon-frame;
    height: $transaction-icon-frame;
    margin: 0 10px;
    transform: rotate(-90deg);

    @media (max-width: $screen-sm-min) {
      margin: 0 4px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: rem(14);
    line-height: rem(16);
  }

  &__status-name {
    margin-left: 4px;
  }

  &__transfer {
    display: flex;
    align-items: center;
    font-size: rem(14);
    line-height: rem(16);
    margin-bottom: rem(6);
  }

  &__flow {
    margin-right: 7px;
  }

  &__info {
    font-size: rem(12);
    line-height: rem(14);
    margin-bottom: rem(6);
  }
}
</style>
