import error from './error';
import feature from './feature';
import settings from './settings';

const DEFAULT_LANGUAGE = 'en';

export default {
  modules: {
    error,
    feature,
    settings,
  },
  state: {
    lockCounter: 0,
    serverTimeDiff: null,
    language: DEFAULT_LANGUAGE,
    redirect: false,
    isSSR: false,
    translationMessages: null,
  },
  getters: {
    isLocked: state => state.lockCounter > 0,
    serverTimeDiff: state => state.serverTimeDiff,
    language: state => state.language,
    isDefaultLanguage: state => state.language === DEFAULT_LANGUAGE,
    isRedirecting: state => state.redirect,
  },
  mutations: {
    setSSR(state, payload) {
      state.isSSR = payload;
    },
    setTranslationMessages(state, payload) {
      state.translationMessages = payload;
    },
    setServerTimeDiff(state, payload) {
      state.serverTimeDiff = payload;
    },
    lock(state) {
      state.lockCounter++;
    },
    unlock(state) {
      if (state.lockCounter === 0) {
        return;
      }

      state.lockCounter--;
    },
    clearLocks(state) {
      state.lockCounter = 0;
    },
    language(state, payload) {
      state.language = payload;
    },
    initRedirect(state) {
      state.redirect = true;
    },
    destroyRedirect(state) {
      state.redirect = false;
    },
  },
};
