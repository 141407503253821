import CardPaymentClient from '../../services/clients/card-payment-client';
import AbstractPaymentProcessor from '../AbstractPaymentProcessor';

export default class BridgerpayPaymentProcessor extends AbstractPaymentProcessor {
  constructor(cardId, invoice, cvv) {
    super('checkout', cardId, invoice);

    this.cvv = cvv;
  }

  getCvv() {
    return this.cvv;
  }

  pay() {
    return CardPaymentClient.getBridgerpayDdc({
      cardId: this.getCardId(),
      invoice: this.getInvoice(),
    }).then(({ data }) => ({
      isBridgerPayIframe: true,
      data,
    }));
  }
}
