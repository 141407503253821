// TODO copied from Common lib, DRY violation
// @see https://gitlab.com/techcloud/shared/frontend-common-lib/-/blob/master/src/plugins/sentry.js

import {
  captureException as sentryCaptureException,
  captureEvent as sentryCaptureEvent,
  configureScope,
} from '@sentry/vue';
import TokenService from './token';

const setUser = () => {
  const user = TokenService.getUser();
  if (user !== null) configureScope(scope => scope.setUser(user));
};

export const captureException = ({ error, extra = {} }) => {
  setUser();
  sentryCaptureException(error, {
    extra,
  });
};

export const captureEvent = ({ event, extra = {} }) => {
  setUser();
  sentryCaptureEvent(event, {
    extra,
  });
};
