<template>
  <div
    v-if="isQuoteReady"
    :class="[
      {
        'exchange-rate-expiration--soon': isExpiresSoonStatus || isExpiredStatus,
      },
      'exchange-rate-expiration'
    ]"
  >
    <DynamicCryptoTimer
      :time="getTime"
      class="crypto-timer"
    />
  </div>
  <FakeFrozenTimer
    v-else
    :time="originalExpirationTimeDiff"
    class="exchange-rate-expiration--soon exchange-rate-expiration"
    :class="{ hidden: isQuoteHasError }"
  />
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import DynamicCryptoTimer from '@/v1/packages/common/components/dynamic-crypto-timer.vue';
import FakeFrozenTimer from '@/v1/components/ExchangeRateExpiration/FakeFrozenTimer';
import { getCryptoTimerValue } from './ExchangeRateExpiration.utils';

export default defineComponent({
  name: 'ExchangeRateExpiration',

  components: {
    DynamicCryptoTimer,
    FakeFrozenTimer,
  },

  setup() {
    const store = useStore();
    const isQuoteReady = computed(() => store.getters['widgetQuote/isQuoteReady'] && !store.getters['widgetQuote/isQuoteLoading']);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const secondsLeft = computed(() => store.getters['external/cryptoTimerSecondsLeft']);
    const cryptoTime = computed(() => store.getters['external/cryptoTimerExpiration']);
    const getTime = computed(() => getCryptoTimerValue(cryptoTime));
    const originalExpirationTimeDiff = computed(() => store.getters['widgetQuote/originalExpirationTimeDiff']);

    const isExpiredStatus = computed(() => secondsLeft.value <= 0);
    const isExpiresSoonStatus = computed(() => (
      secondsLeft.value <= 60 * 2 && secondsLeft.value > 0
    ));

    return {
      isQuoteReady,
      isQuoteHasError,
      getTime,

      isExpiredStatus,
      isExpiresSoonStatus,

      originalExpirationTimeDiff,
    };
  },
});
</script>

<style lang="scss" scoped>
.exchange-rate-expiration {
  color: #999;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3.5rem;

  &__link {
    color: inherit;
  }

  // TODO remove, hack to override funnel's timer width style
  ::v-deep(.timerText) {
    width: auto !important;
  }

  @media (min-width: $desktop-min) {
    font-size: 16px;
  }

  @media (min-width: $desktop-xlg-min) {
    font-size: 19px;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    font-size: calcVh(16, $widget-h-sm);
    line-height: calcVh(18, $widget-h-sm);
    margin-top: calcVh(56, $widget-h-sm);
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    font-size: calcVh(18, $widget-h-xlg);
    line-height: calcVh(24, $widget-h-xlg);
    margin-top: calcVh(56, $widget-h-xlg);
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    font-size: calcVh(18, $widget-h-xxlg);
    line-height: calcVh(24, $widget-h-xxlg);
    margin-top: calcVh(56, $widget-h-xxlg);
  }

  &--soon {
    color: #e79c2c;

    // TODO should be removed from here, overrides for tooltip from funnel
    .crypto-timer {
      ::v-deep(.pb-tooltip__icon .icon) {
        background-image: url(@/v1/assets/images/widget/question-yellow.svg) !important;
        background-size: contain;
      }
    }
  }

  &--expired {
    color: red;
  }

  // TODO should be removed from here, overrides for tooltip from funnel
  .crypto-timer {
    ::v-deep(.pb-tooltip) {
      margin-left: 4px;
    }

    ::v-deep(.pb-tooltip a) {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid rgba(146, 83, 193, 0.15);
      transition: border-color 0.2s linear;
      text-decoration: none;

      &:hover {
        border-color: rgba(0, 0, 0, 0.6);
      }
    }

    ::v-deep(.pb-tooltip) {
      vertical-align: text-bottom;
    }

    // TODO drop this overrides when timer will use SVG icons
    ::v-deep(.pb-tooltip__icon) {
      display: flex;

      & .icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        background-image: none;
        background-color: $white;
        mask-image: url(@/v1/assets/images/widget/question.svg);
        mask-repeat: no-repeat;
        mask-size: contain;

        &::before {
          display: none;
        }

        @media (min-width: $desktop-xlg-min) {
          width: 20px;
          height: 20px;
        }

        @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
          width: calcVh(18, $widget-h-sm);
          height: calcVh(18, $widget-h-sm);
        }

        @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
          width: calcVh(20, $widget-h-xlg);
          height: calcVh(20, $widget-h-xlg);
        }

        @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
          width: calcVh(20, $widget-h-xxlg);
          height: calcVh(20, $widget-h-xxlg);
        }
      }
    }

    ::v-deep(.pb-tooltip__content) {
      opacity: 1;
    }
  }
}

.exchange-rate-expiration.hidden {
  display: none;
};
</style>
