import client from '../network/clients/authentication';
import { AuthError, OtpError, TooEarlyError } from '../models/auth-error';
import UserLocaleStorage from './user-locale-storage';
import { getDeviceId } from '@/utils/loadNSure';

const post = async (path, postBody = {}, options = {}) => {
  try {
    const { data } = await client.post(`/public/authentication-service/v1${path}`, postBody, options);

    return data;
  } catch ({ response }) {
    const { status } = response;
    if (status === 422) {
      throw new OtpError(response);
    }
    if (status === 425) {
      throw new TooEarlyError(response);
    }
    throw new AuthError(response);
  }
};

// @see https://gitlab.com/techcloud/pb/authentication-service/-/blob/master/docs/api/openapi.public.v1.yaml
export default {
  async startSession(postBody) {
    return post('/session', postBody, {
      headers: {
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async startRegistration(sessionId, postBody) {
    return post(`/session/${sessionId}/register`, postBody, {
      headers: {
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async confirmEmailOtp(sessionId, postBody) {
    return post(`/session/${sessionId}/confirm`, postBody, {
      headers: {
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async confirmPhoneOtp(sessionId, postBody) {
    return post(`/session/${sessionId}/confirm`, postBody, {
      headers: {
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async resendOtp(sessionId) {
    return post(`/session/${sessionId}/resend-otp`, {}, {
      headers: {
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async logout() {
    return post('/users/logout', {}, {
      headers: {
        'X-User-Language': UserLocaleStorage.getLocale() || 'en',
        'X-Device-ID': await getDeviceId(),
      },
    });
  },

  async refresh(refreshToken) {
    return post('/jwt/refresh', {
        refreshToken,
      }, {
        headers: {
          'X-User-Language': UserLocaleStorage.getLocale() || 'en',
          'X-Device-ID': await getDeviceId(),
        },
      });
  },
};
