export default () => ({
  namespaced: true,
  state: {
    fields: [],
  },
  getters: {
    fields: state => state.fields,
  },
  mutations: {
    setFields(state, payload) {
      state.fields = payload;
    },
    updateField(state, payload) {
      state.fields = state.fields.map(field => {
        if (field.getId() === payload.getId()) {
          return payload;
        }

        return field;
      });
    },
    clearFields(state) {
      state.fields = [];
    },
  },
  actions: {
    init({ commit, getters }, payload) {
      const fields = payload.map(field => {
        const updatedField = getters.fields.find(
          currentField => currentField.getId() === field.getId(),
        ) || field;
        if (updatedField.getErrorMessage() === null) {
          updatedField.setErrorMessage(field.getErrorMessage());
        }

        return updatedField;
      });
      commit('setFields', fields);
    },
    setFieldErrors({ commit, getters }, errors) {
      const { fields } = getters;

      errors.forEach(error => {
        const field = fields.find(item => item.getId() === error.property_path);
        if (field) {
          field.setErrorMessage(error.message);
          commit('updateField', field);
        }
      });
    },

    clearFields({ commit }) {
      commit('clearFields');
    },

    resetFieldsErrors({ commit, getters }) {
      const { fields } = getters;
      fields.forEach(field => {
        field.setErrorMessage(null);
        commit('updateField', field);
      });
    },
  },
});
