import store from '@/v1/store/index';

// @TODO: remove store from here
const getBaseUrl = () => (store.getters.isDefaultLanguage ? '' : `/${store.getters.language}`);

export default {
  generate(uri, params) {
    let url = getBaseUrl() + uri;
    if (typeof params === 'object' && Object.keys(params).length > 0) {
      url = `${url
      }?${
        Object.keys(params)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
          )
          .join('&')}`;
    }

    return url;
  },

  getMoneyServiceIconByName(iconName) {
    return `/resources/money-services/${iconName}.svg`;
  },

  // TODO: clean up, if not used anymore
  getMoneyServiceIconPath(filename) {
    return `/images/money-services/${filename}`;
  },
};
