import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { isV2 } from '@/services/apiVersion';

const paymentMethodLabel = ref('');
const CHECK_INTERVAL = 250;

export const usePaymentMethodLabel = () => {
  const store = useStore();

  const intervalId = setInterval(() => {
    if (!isV2()) {
      return;
    }

    watch([
      () => store.getters['v2/paymentMethods/paymentMethod'],
      () => store.getters['v2/paymentMethods/payoutMethod'],
    ], ([paymentMethod, payoutMethod]) => {
      if (paymentMethod.id) {
        paymentMethodLabel.value = paymentMethod.name || paymentMethod.displayName || paymentMethod.paymentMethodName;
        return;
      }

      if (payoutMethod.id) {
        paymentMethodLabel.value = payoutMethod.name || payoutMethod.displayName;
      }
    });
    clearInterval(intervalId);
  }, CHECK_INTERVAL);

  return {
    paymentMethodLabel,
  };
};
