const GPAY_SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js';

const getAlreadyIncludedGooglePayScript = () => {
  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].getAttribute('src') === GPAY_SCRIPT_URL) return scripts[i];
  }

  return undefined;
};

export const attachGooglePayScript = () => new Promise((resolve, reject) => {
  const existingGooglePayScript = getAlreadyIncludedGooglePayScript();

  if (existingGooglePayScript) {
    document.head.removeChild(existingGooglePayScript);
  }

  const googlePayScript = document.createElement('script');
  googlePayScript.src = GPAY_SCRIPT_URL;
  googlePayScript.async = true;
  googlePayScript.onload = resolve;
  googlePayScript.onerror = reject;
  document.head.appendChild(googlePayScript);
});
