import { captureException } from '@/v1/packages/common/plugins/sentry';
// eslint-disable-next-line import/no-cycle
import store from '@/v1/store';
import { getCorrelationId } from '@/v1/packages/common/services/correlation';

export function handleResponseError(error) {
  captureException({
    error,
    extra: {
      ...error.config,
      'x-amzn-trace-id': error?.response?.headers['x-amzn-trace-id'] || null,
    },
  });
  store.dispatch('external/setError', error);

  return Promise.reject(error);
}

export function addCorrelationId(config) {
  config.headers['X-Correlation-ID'] = getCorrelationId();

  return config;
}
