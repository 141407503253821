import Field from '../field';
import Hint from '../hint';
import {
  VerificationFieldStatus,
  VerificationFieldStatusLegacy,
} from './status';

export class VerificationField {
  constructor(
    name,
    status,
    slug,
    group,
    isPdfAllowed,
    value,
    hint,
    formattedValue,
    errorMessage,
  ) {
    this.name = name;
    this.status = status;
    this.slug = slug;
    this.group = group;
    this.isPdfAllowed = isPdfAllowed;
    this.value = value;
    this.hint = new Hint(hint);
    this.formattedValue = formattedValue;
    this.errorMessage = errorMessage;
    this.hasError = false;
  }

  getHint() {
    return this.hint;
  }

  isUnverified() {
    return this.status === VerificationFieldStatusLegacy.STATUS_UNVERIFIED;
  }

  isUploaded() {
    return this.status === VerificationFieldStatusLegacy.STATUS_UPLOADED;
  }

  isAccepted() {
    return this.status === VerificationFieldStatusLegacy.STATUS_ACCEPTED;
  }

  isRejected() {
    return this.status === VerificationFieldStatusLegacy.STATUS_REJECTED;
  }

  unverify() {
    this.status = VerificationFieldStatusLegacy.STATUS_UNVERIFIED;
  }

  upload() {
    this.status = VerificationFieldStatusLegacy.STATUS_UPLOADED;
  }

  accept() {
    this.status = VerificationFieldStatusLegacy.STATUS_ACCEPTED;
  }

  reject() {
    this.status = VerificationFieldStatusLegacy.STATUS_REJECTED;
  }

  addError(errorMessage) {
    this.errorMessage = errorMessage;
    this.hasError = true;
  }

  removeError() {
    this.errorMessage = null;
    this.hasError = false;
  }
}

const TYPE_SUMSUB_ACTION = 'sumsub-action';

export class ExtraVerificationField extends Field {
  constructor({
    id,
    status,
    name,
    type,
    value,
    hint,
    optional,
    max_length,
    options,
    is_pdf_allowed = null,
    reject_message = null,
    cooling_off_finish_in = null,
  }) {
    // eslint-disable-line camelcase
    super({ id, name, type, value, hint, optional, max_length, options });
    this.status = status;
    this.isPdfAllowed = is_pdf_allowed; // eslint-disable-line camelcase
    this.errorMessage = reject_message; // eslint-disable-line camelcase
    this.coolingOffFinishIn = cooling_off_finish_in; // eslint-disable-line camelcase
  }

  isSumSubAction() {
    return this.type === TYPE_SUMSUB_ACTION;
  }

  getStatus() {
    return this.status;
  }

  setUnverified() {
    this.status = VerificationFieldStatus.STATUS_UNVERIFIED;
  }

  isUnverified() {
    return this.status === VerificationFieldStatus.STATUS_UNVERIFIED;
  }

  isUploaded() {
    return this.status === VerificationFieldStatus.STATUS_UPLOADED;
  }

  isAccepted() {
    return this.status === VerificationFieldStatus.STATUS_ACCEPTED;
  }

  isRejected() {
    return this.status === VerificationFieldStatus.STATUS_REJECTED;
  }

  isDisabled() {
    return this.isAccepted() || this.isUploaded();
  }

  hasError() {
    return this.isRejected() || super.hasError();
  }

  getCoolingOffFinishIn() {
    return this.coolingOffFinishIn;
  }

  hasCoolOffTimer() {
    return !!this.coolingOffFinishIn;
  }
}
