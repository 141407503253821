import { getTimerFromTimestamp } from '../utils/getTimerFromTimestamp';

class OtpSession {
  constructor(expirationTime, canBeResentAfter, attempts, context) {
    this.expirationTime = new Date(expirationTime);
    this.canBeResentAfter = new Date(canBeResentAfter);
    this.attempts = attempts;
    this.context = context;
    this.confirmed = false;
  }

  hasExpired() {
    return (this.getExpirationTime() - new Date()) <= 0;
  }

  getExpirationTime() {
    return this.expirationTime;
  }

  getExpirationTimer() {
    const timeLeft = this.getExpirationTime() - new Date();

    return getTimerFromTimestamp(timeLeft);
  }

  getCanBeResentAfter() {
    return this.canBeResentAfter;
  }

  hasNoAttemptsLeft() {
    return this.attempts < 1;
  }

  getAttempts() {
    return this.attempts;
  }

  getContext() {
    return this.context;
  }

  isValid() {
    return !this.hasExpired() && !this.hasNoAttemptsLeft();
  }

  isConfirmed() {
    return this.confirmed;
  }

  markAsExpired() {
    this.expirationTime = 0;
  }
}

export class Session {
  constructor({ sessionId, expirationTime, canBeResentAfter, attempts }, email, locale, flow, theme, sourceInfo) {
    this.sessionId = sessionId;
    this.emailOtpSession = new OtpSession(expirationTime, canBeResentAfter, attempts, email);
    this.phoneOtpSession = null;
    this.locale = locale;
    this.flow = flow;
    this.theme = theme;
    this.sourceInfo = sourceInfo;
    this.emailProvided = false;
  }

  getSourceInfo() {
    return this.sourceInfo;
  }

  setEmailProvided() {
    this.emailProvided = true;
  }

  isEmailProvided() {
    return this.emailProvided;
  }

  getTheme() {
    return this.theme;
  }

  getFlow() {
    return this.flow;
  }

  getLocale() {
    return this.locale;
  }

  setEmailOtpSession(expirationTime, canBeResentAfter, attempts, email) {
    this.emailOtpSession = new OtpSession(expirationTime, canBeResentAfter, attempts, email);
  }

  setPhoneOtpSession(expirationTime, canBeResentAfter, attempts, phoneNumber) {
    this.phoneOtpSession = new OtpSession(expirationTime, canBeResentAfter, attempts, phoneNumber);
  }

  getSessionId() {
    return this.sessionId;
  }

  getEmailOtpSession() {
    return this.emailOtpSession;
  }

  hasPhoneOtpSession() {
    return this.phoneOtpSession !== null;
  }

  getPhoneOtpSession() {
    return this.phoneOtpSession;
  }

  isEmailSessionValid() {
    return this.emailOtpSession.isValid();
  }

  isPhoneSessionValid() {
    return this.phoneOtpSession !== null && this.phoneOtpSession.isValid();
  }

  confirmEmailOtpSession() {
    this.emailOtpSession.confirmed = true;
  }

  getExpirationTime() {
    if (this.hasPhoneOtpSession()) {
      return this.phoneOtpSession.expirationTime;
    }

    return this.emailOtpSession.expirationTime;
  }
}
