import Hint from './hint';

const FIELD_ID_ACCOUNT = 'account';

export default class Field {
  constructor({
    id,
    name,
    type,
    value,
    hint,
    optional,
    max_length,
    options = [],
  }) {
    // eslint-disable-line camelcase
    this.id = id;
    this.name = name;
    this.type = type;
    this.value = value;
    this.hint = new Hint(hint);
    this.optional = optional;
    this.maxLength = max_length; // eslint-disable-line camelcase
    this.options = options;
    this.errorMessage = null;
  }

  getId() {
    return this.id;
  }

  isAccount() {
    return this.id === FIELD_ID_ACCOUNT;
  }

  getName() {
    return this.name;
  }

  getType() {
    return this.type;
  }

  getValue() {
    return this.value;
  }

  hasValue() {
    return this.value !== null && typeof this.value !== 'undefined';
  }

  isEmpty() {
    return this.value === '' || !this.hasValue();
  }

  setValue(value) {
    this.value = value;
  }

  getHint() {
    return this.hint;
  }

  isOptional() {
    return this.optional === true;
  }

  getMaxLength() {
    return this.maxLength;
  }

  getOptions() {
    return this.options;
  }

  hasError() {
    return this.errorMessage !== null;
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  isDisabled() {
    return false;
  }

  hasAutocomplete() {
    // XXX: Disable autocomplete for field account
    return this.getId() !== 'account';
  }
}
