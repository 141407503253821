import { isNewExchangeFormFlowEnabled } from '@/v1/services/exchangeFormFlow';

export const NAME = 'v2-payment-methods';

export default {
  path: '/v2/payment-methods',
  name: 'v2-payment-methods',
  component: () => import('@/v1/views/v2/PaymentMethodsScreen'),
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
