import * as Sentry from '@sentry/vue';

import { redirectIfCriticalError } from '@/v1/utils/redirectIfCriticalError';

const handleError = event => {
  const { error, reason = {} } = event;
  if (error instanceof HealthCheckError || reason instanceof HealthCheckError) {
    Sentry.captureException(error);
  }
  redirectIfCriticalError(event);
};

export function init() {
  window.addEventListener('error', handleError);
  window.addEventListener('unhandledrejection', handleError);
}
