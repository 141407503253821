import { ref } from 'vue';
import { useRouter } from 'vue-router';

const callbacks = [];
const isPopStateDetected = ref(false);
const emitBackButtonEvent = () => {
  callbacks.forEach(cb => cb());
};

export const onBackButtonEvent = cb => {
  setupRouter();
  callbacks.push(cb);
};

window.addEventListener('popstate', e => {
  if (e?.state?.back === e?.state?.current) return;

  isPopStateDetected.value = true;
  setTimeout(() => {
    isPopStateDetected.value = false;
  }, 0); //
});

function setupRouter() {
  const router = useRouter();
  router.beforeEach(() => {
    if (!isPopStateDetected.value) {
      return true;
    }

    emitBackButtonEvent();
    return false;
  });
}
