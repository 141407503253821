export class Tokens {
  constructor({ jwt, jwtExpiresAt, refreshToken, refreshTokenExpiresAt }) {
    this.jwt = jwt;
    this.refreshToken = refreshToken;
    this.refreshTokenExpiresAt = new Date(refreshTokenExpiresAt);
    this.jwtExpiresAt = new Date(jwtExpiresAt);
  }

  getJWT() {
    return this.jwt;
  }

  getJwtExpiresAt() {
    return this.jwtExpiresAt;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getRefreshTokenExpiresAt() {
    return this.refreshTokenExpiresAt;
  }
}
