export const NAME = 'applePay';

export default {
  path: '/apple-pay-one-click',
  name: NAME,
  component: () => import('@/v1/views/ApplePayOneClick/ApplePayOneClickScreen.vue'),
  meta: {
    shouldBePreloaded: true,
    requiresAuth: false,
  },
};
