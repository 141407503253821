import ErrorTypeEnum from './ErrorTypeEnum';

const sdkErrorGroup = [
  ErrorTypeEnum.INACTIVE_USER,
  ErrorTypeEnum.CRITICAL_ERROR,
  ErrorTypeEnum.GENERAL_ERROR,
  ErrorTypeEnum.FAILED_TO_RUN,
];

export default class WidgetError extends Error {
  type = ErrorTypeEnum.GENERAL_ERROR;

  constructor(type, errorBody, options) {
    errorBody = errorBody || {};
    super(errorBody);

    if (typeof type !== 'symbol') {
      this.type = ErrorTypeEnum[type] || ErrorTypeEnum.UNKNOWN_ERROR;
    } else {
      this.type = type;
    }

    this.id = Symbol('errorId');
    this.message = errorBody.message;
    this.stack = errorBody.stack;
    this.correlationId = options.correlationId;
  }

  get sdkVisible() {
    return sdkErrorGroup.includes(this.type);
  }
}
