<template>
  <div class="loader">
    <div class="loader__header">
      <div
        class="loader__spinner"
        :style="{
          '--size': size
        }"
      />

      <div
        v-if="withTitle"
        class="loader__title"
      >
        {{ $t('widget.loading.title') }}
      </div>
    </div>

    <div
      v-if="withTitle"
      class="loader__body"
    >
      {{ $t('widget.loading.description') }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loader',

  props: {
    title: {
      type: String,
      required: false,
    },

    description: {
      type: String,
      required: false,
    },

    withTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      default: '64px',
    },
  },
});
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  &__header {
    font-size: rem(28);
    line-height: rem(32);
    text-align: center;
    font-weight: 700;
    text-align: center;

    @media (max-width: $tablet-max) {
      text-align: left;
      font-size: 24px;
      line-height: 26px;
    }
  }

  &__title {
    line-height: 40px;

    @media (max-width: $tablet-max) {
      line-height: 28px;
      font-size: 24px;
    }
  }

  &__body {
    min-height: rem(256);
    min-width: rem(200);
    max-width: rem(416);

    margin: auto;
    margin-bottom: rem(40);

    font-size: rem(16);
    line-height: rem(22);
    text-align: center;

    @media (max-width: $tablet-max) {
      min-height: auto;
      min-width: auto;
    }
  }

  &__spinner {
    @include spinerIcon($size: var(--size));

    margin: 0 auto;
  }
}
</style>
