import getParams from '@/v1/utils/getParams';
import store from '@/v1/store';
import CardClient from '@/v1/packages/common/services/clients/card-client';
import { PaymentProcessorFactory } from '@/v1/packages/common/payment-processor/PaymentProcessorFactory';
import { getLocaleByLanguage } from '@/v1/packages/common/services/util';
import { isElectron } from '@/utils/detect';
import { isInsideIframe } from '@/utils/isInsideIframe';
import { getReturnUrl } from '@/v1/utils/getReturnUrl';

const APPLE_PAY = 'applePay';

export function isApplePayOneClickFlow() {
  const { indexPage } = getParams();
  return indexPage === APPLE_PAY && store.getters.isOneClickFlow && window.ApplePaySession?.canMakePayments() && !isElectron() && !isInsideIframe();
}

const storeLocal = {};
export const useApplePayOneClickStore = () => {
  const isSellCryptoFlow = store.getters.isSellCryptoFlow;

  const setPaymentData = ({ token, cardholderName, session, currency, language, billingAddress }) => {
    storeLocal.token = token;
    storeLocal.cardholderName = cardholderName;
    storeLocal.session = session;
    storeLocal.currency = currency;
    storeLocal.language = language;
    storeLocal.billingAddress = billingAddress;
  };

  const getPaymentData = () => storeLocal;

  async function payWithApplePay(invoice) {
    const aftCheckoutFeature = store.getters['feature/aftCheckoutFeature'];

    const { data } = await CardClient.applePay({
      paymentToken: storeLocal.token,
      cardholderName: storeLocal.cardholderName,
      invoice,
      billingAddress: aftCheckoutFeature.isEnabled() ? storeLocal.billingAddress : undefined,
      flow: isSellCryptoFlow ? 'payout' : 'payment',
    });
    const { id: cardId } = data;

    const { paymentProcessorId } = await store.dispatch('card/getPreferrablePaymentProcessor', {
      cardId,
      invoice,
      currency: storeLocal.currency,
    });

    const paymentProcessor = new PaymentProcessorFactory(
      paymentProcessorId,
      cardId,
      invoice,
      null,
    ).build();

    return paymentProcessor.initApplePayPayment({
      paymentToken: storeLocal.token,
      browserLanguage: getLocaleByLanguage(storeLocal.language),
      returnUrl: getReturnUrl(),
    });
  }

  return {
    setPaymentData,
    getPaymentData,
    payWithApplePay,
  };
};
