import hash from 'hash-sum/hash-sum';
import { StorageService } from '@/services/storageService';

const salt = () => hash(window._user_id);

export default {
  get(key) {
    try {
      return StorageService.get(`${salt()}.${key}`);
    } catch (e) {
      if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
        // XXX: suppress error when client has corrupted localStorage on his device
        return '';
      }

      return Promise.reject(e);
    }
  },
  remove(key) {
    StorageService.remove(`${salt()}.${key}`);
  },
  put(key, value) {
    StorageService.set(`${salt()}.${key}`, value);
  },
};
