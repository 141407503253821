import { WIDGET_APP_TYPE } from '../constants/application-types';

export default {
  namespaced: true,

  state: {
    appType: null,
  },

  getters: {
    isWidget: ({ appType }) => appType === WIDGET_APP_TYPE,
  },

  mutations: {
    mutateAppType(state, appType) {
      state.appType = appType;
    },
  },

  actions: {
    setAppType({ commit, state }, appType) {
      if (state.appType) {
        return;
      }

      commit('mutateAppType', appType);
    },
  },
};
