<template>
  <div
    v-show="canShowButton"
    :class="classList"
  >
    {{ $t('widget.close-widget') }}
  </div>
</template>

<script>
import { canClose } from '@/services/widgetSettings';

const modes = {
  modal: 'btn btn-lg btn-secondary bold',
  view: 'btn btn-widget btn-secondary',
};

export default {
  name: 'CloseWidgetButton',
  setup({ mode }) {
    return {
      classList: modes[mode] || modes.view,
      canShowButton: canClose(),
    };
  },
};
</script>
