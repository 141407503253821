import { isNewExchangeFormFlowEnabled } from '@/v1/services/exchangeFormFlow';

export const NAME = 'exchange-form';

export default {
  path: '/exchange-form',
  name: NAME,
  component: () => import('@/v1/views/ExchangeFormScreen'),
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
