import { StorageService } from '@/services/storageService';

const APPLE_PAY_DISMISSED_KEY = 'apple-key-dismissed';

const setApplePayDismissed = () => {
  StorageService.set(APPLE_PAY_DISMISSED_KEY, 'true');
};

const clearApplePayDismissed = () => {
  StorageService.remove(APPLE_PAY_DISMISSED_KEY);
};

const isApplePayDismissed = () => !!StorageService.get(APPLE_PAY_DISMISSED_KEY);

export default {
  setApplePayDismissed,
  clearApplePayDismissed,
  isApplePayDismissed,
};
