import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  configureScope,
} from '@sentry/vue';
import TokenService from '../services/token';
import { getCorrelationId } from '../services/correlation';

const setUser = () => {
  const user = TokenService.getUser();
  if (user !== null) configureScope(scope => scope.setUser(user));
};

export const setTags = tags => {
  configureScope(scope => {
    Object.entries(tags).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
  });
};

configureScope(scope => {
  scope.setTag('correlation_id', getCorrelationId());
});

export const captureException = ({ error, extra = {} }) => {
  setUser();
  if (!(error instanceof Error)) {
    error = error instanceof Object ? new Error(JSON.stringify(error)) : new Error(error);
  }
  sentryCaptureException(error, { extra });
};

export const captureMessage = ({ message, extra = {} }) => {
  setUser();
  sentryCaptureMessage(message, { extra });
};
