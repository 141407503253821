export const NAME = 'auth';

export default {
  path: '/auth',
  name: NAME,
  component: () => import('@/v1/views/AuthFlow'),
  meta: {
    shouldBePreloaded: true,
  },
};
