import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useMediaQuery } from '@vueuse/core';

const DESCTOP_BREAKPOINT = 1024;
const ERROR_ROUTE_PATH = '/critical-error';

export const isErrorRoute = computed(() => {
  const route = useRoute();
  return route.path === ERROR_ROUTE_PATH;
});

const isThemeAvailable = computed(() => {
  const store = useStore();
  const { themeConfig } = store.getters;
  const isThemeExist = Object.keys(themeConfig).length > 0;
  return isThemeExist;
});

export const shouldChangeAppearanceForError = computed(
  () => isErrorRoute.value && !isThemeAvailable.value,
);

export const headerCloseTextStyle = computed(() => (shouldChangeAppearanceForError.value ? {
  color: 'white',
} : {}));

export const headerCloseIconStyle = computed(() => (shouldChangeAppearanceForError.value ? {
  backgroundColor: 'white',
} : {}));

const isDesktop = useMediaQuery(`(min-width: ${DESCTOP_BREAKPOINT}px)`);

export const headerBottomSpace = computed(() => (
  isDesktop.value || !shouldChangeAppearanceForError.value ? {} : {
    paddingBottom: 0,
    marginBottom: '-50px',
  }
));

export const widgetWrapperAlignment = computed(() => (isErrorRoute.value ? {
  justifyContent: 'center',
} : {}));
