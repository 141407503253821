import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import qs from 'qs';

import { captureException } from '../plugins/sentry';
import TokenService from './token';
import { getCsrf } from './util';

import { SERVER_TIMESTAMP_HEADER, AUTHORIZATION } from '../constants/headers';
import EventNames from '../models/eventNames';

import eventBus from '../helpers/eventBus';
import { getCorrelationId } from './correlation';

import { DeviceIdentificationError } from '@/exceptions/DeviceIdentificationError';

let instance = null;

const cancelBucket = {};

export const initAPI = store => {
  instance = axios.create({
    headers: {
      Accept: 'application/json',
    },
    paramsSerializer: qs.stringify,
  });

  axiosRetry(instance, {
    retries: 2,
    retryDelay: retryCount => retryCount * 500,
    retryCondition: error => {
      if (Object.keys(cancelBucket).length > 0) {
        return isNetworkOrIdempotentRequestError(error);
      }

      return false;
    },
  });

  instance.interceptors.request.use(
    config => {
      config.headers['Content-Type'] = 'application/json';

      config.headers['X-Correlation-ID'] = getCorrelationId();

      if (TokenService.hasToken()) {
        config.headers[AUTHORIZATION] = `Bearer ${TokenService.getToken()}`;
      }

      config.metadata = { startTime: new Date() };

      const { url } = config;
      if (cancelBucket[url]) {
        // XXX: cancel request for current url
        cancelBucket[url].abort();
      }

      const abortController = new AbortController();
      config.signal = abortController.signal;

      cancelBucket[url] = abortController;

      if (config.method !== 'get' && url.indexOf('/') === 0) {
        // Add _csrf token header for internal yii2 calls
        const _csrf = getCsrf();
        if (_csrf !== null) {
          config.headers['X-CSRF-TOKEN'] = _csrf;
        }
      }

      return config;
    },
    error => Promise.reject(error),
  );

  instance.interceptors.response.use(
    response => {
      if (typeof response.headers[SERVER_TIMESTAMP_HEADER] !== 'undefined') {
        const endTime = new Date();
        const responseDuration = endTime - response.config.metadata.startTime;
        const serverTime = response.headers[SERVER_TIMESTAMP_HEADER] * 1000; // ms

        store.commit(
          'setServerTimeDiff',
          serverTime + responseDuration - Date.now(),
        );
      }

      return response;
    },
    error => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const { config, response } = error;
      if (response === undefined) {
        return Promise.reject(error);
      }

      if (response.status === 'missing-token') {
        if (TokenService.hasRefreshToken()) {
          captureException({
            error,
            extra: {
              message:
                'Tried to refresh JWT, but request was missing refresh token, but it exists in cookies.',
            },
          });
        }
        eventBus.emit(EventNames.SESSION_TIMEOUT);
        return Promise.reject(error);
      }

      const { url: requestUrl } = config || {};

      // XXX: Need to return wallet-service errors (PD-13274)
      if (requestUrl.indexOf('/public/web-wallets/') !== -1) {
        return Promise.reject(error);
      }

      store.dispatch('error/setError', error);

      if (store.getters['error/isUnauthorized']) {
        const extra = {
          cookieEnabled: navigator.cookieEnabled,
          token: TokenService.getToken(),
          refreshToken: TokenService.getRefreshToken(),
          cookies: document.cookie,
        };

        const { config: eConfig, response: eResponse } = error;
        if (eConfig) {
          extra.config = eConfig;
        }
        if (eResponse) {
          extra.response = eResponse;
        }

        if (
          requestUrl.indexOf('/jwt/refresh') === -1
          && requestUrl.indexOf('/') !== 0
        ) {
          return TokenService.requestNewToken()
            .then((response) => {
              if (!response || !response.jwt) {
                return Promise.reject(new Error('JWT not provided'));
              }

              config.headers[AUTHORIZATION] = `Bearer ${response.jwt}`;

              return instance.request(config).catch(e => {
                const { status } = e;
                if (status && status === 404) {
                  // XXX: ignore status code 404 (active payment)
                  return Promise.resolve();
                }
                return Promise.reject(e);
              });
            })
            .catch(e => {
              if (e instanceof DeviceIdentificationError) {
                return Promise.reject(e);
              }

              captureException({ error: e, extra });
              return Promise.reject(error);
            });
        }
        captureException({ error, extra });

        eventBus.emit(EventNames.SESSION_TIMEOUT);
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default () => {
  if (instance === null) {
    throw new Error('Please create axios instance!');
  }

  return instance;
};

export const cancelPendingRequests = async () => {
  return new Promise(resolve => {
    Object.keys(cancelBucket).forEach(key => {
      cancelBucket[key].abort();
      delete cancelBucket[key];
    });
    resolve();
  });
};
