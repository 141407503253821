<template>
  <div class="widget-menu-bg" />
  <div
    v-if="isOpened"
    class="widget-menu-container"
    :style="widgetMenuStyles"
  >
    <StaticPageLayout
      v-if="hasCurrentItem"
      :current-item="currentItem"
    />

    <template v-else>
      <WidgetMenuLayout />
      <AdditionalInformation />
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useWidgetMenuStyles } from './composables/useWidgetMenuStyles';

import WidgetMenuLayout from './WidgetMenuLayout';
import StaticPageLayout from './StaticPageLayout';
import AdditionalInformation from './AdditionalInformation';

export default defineComponent({
  name: 'WidgetMenuContainer',

  components: {
    AdditionalInformation,
    WidgetMenuLayout,
    StaticPageLayout,
  },

  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const hasCurrentItem = computed(() => props.currentItem !== null);
    const { widgetMenuStyles } = useWidgetMenuStyles();

    return {
      hasCurrentItem,
      widgetMenuStyles,
    };
  },
});
</script>

<style lang="scss" scoped>

@mixin widget-menu-base() {
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 6px;
}

$z-index-widget-menu: 102;

.widget-menu-bg {
  @include widget-menu-base;
  z-index: $z-index-widget-menu;
}

.widget-menu-container {
  @include widget-menu-base;
  z-index: $z-index-widget-menu;
  padding: rem(40) rem(72) rem(40) rem(72);

  @media (max-width: $tablet-max) {
    padding: 40px 56px;
  }

  @media (max-width: $screen-sm-min) {
    padding: 24px 30px 24px 30px !important;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    padding-top: 5.2vh;
    padding-bottom: 3.125vh;
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    padding-top: 5.22vh;
    padding-bottom: 3.13vh;
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    padding-top: 5.3vh;
    padding-bottom: 3.18vh;
  }
}
</style>
