import { computed } from 'vue';
import { useStore } from 'vuex';

const CRYPTO = 'crypto';

export function useTransactionFlow() {
  const store = useStore();

  const isBuyCryptoFlow = computed(() => store.getters['isBuyCryptoFlow']);
  const isSellCryptoFlow = computed(() => store.getters['isSellCryptoFlow']);
  const payment = computed(() => store.getters['external/funnelPayment']);
  const payout = computed(() => store.getters['external/funnelPayout']);
  const isSwapCryptoFlow = payment.value.type === CRYPTO && payment.value.type === payout.value.type;

  return {
    isBuyCryptoFlow,
    isSellCryptoFlow,
    isSwapCryptoFlow,
  };
}
