export default class AbstractPaymentProcessor {
  constructor(name, cardId, invoice) {
    this.name = name;
    this.cardId = cardId;
    this.invoice = invoice;
  }

  getName() {
    return this.name;
  }

  getCardId() {
    return this.cardId;
  }

  getInvoice() {
    return this.invoice;
  }

  pay() {
    throw new Error('Please implement pay method');
  }

  initApplePayPayment() {
    return Promise.reject(
      new Error('ApplePay not supported for this processor'),
    );
  }

  initGooglePayPayment() {
    return Promise.reject(
      new Error('GooglePay not supported for this processor'),
    );
  }
}
