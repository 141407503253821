import { TransactionFlowStep as steps } from '../models/steps';
import Natural from './natural';

export default class extends Natural {
  getStepIndex() {
    return this.steps.findIndex(
      step => step.name === steps.STEP_EXTRA_VERIFICATION,
    );
  }

  current() {
    return { name: steps.STEP_EXTRA_VERIFICATION };
  }
}
