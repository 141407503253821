import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import inIframe from '@/v1/packages/transaction/utils/iframe';

import { emitToParent } from '@/services/widgetApiEvents';

export const useLogout = () => {
  const store = useStore();
  const router = useRouter();

  const softLogout = async () => {
    await store.dispatch('logout');

    router.push('/');
  };

  const iFrameLogout = async () => {
    await store.dispatch('logout');

    emitToParent('state', {
      state: 'closed',
      action: 'logout',
    });
  };

  const standaloneLogout = async () => {
    await store.dispatch('logout');

    window.location.reload();
  };

  const logoutWidget = async () => {
    if (inIframe()) {
      await iFrameLogout();
      return;
    }

    await standaloneLogout();
  };

  return {
    logoutWidget,

    standaloneLogout,
    iFrameLogout,

    softLogout,
  };
};
