export default class FormFactory {
  constructor({ action, attributes, inputs, method }) {
    this.action = action;
    this.attributes = attributes;
    this.inputs = inputs;
    this.method = method;
  }

  createFormElement() {
    const form = document.createElement('form');

    form.setAttribute('method', this.method);
    form.setAttribute('action', this.action);

    if (this.attributes === Object(this.attributes)) {
      for (const [attribute, value] of Object.entries(this.attributes)) {
        form.setAttribute(attribute, value);
      }
    }

    for (const [name, value] of Object.entries(this.inputs)) {
      const input = document.createElement('input');
      input.setAttribute('name', name);
      input.setAttribute('value', value);
      input.setAttribute('type', 'hidden');
      form.appendChild(input);
    }

    form.setAttribute('style', 'visibility:hidden; position:absolute;');

    return form;
  }
}
