import {
  cardStore,
  cardSumsubStore,
} from '@/v1/packages/common/store/modules/card';
import cardAuth from '@/v1/packages/common/store/modules/cardAuth';
import cryptoTimer from '@/v1/packages/common/store/modules/cryptoTimer';
import customFields from '@/v1/packages/common/store/modules/customFields';
import quote from '@/v1/packages/common/store/modules/quote';
import sumsubExtraVerification from '@/v1/packages/common/store/modules/sumsubExtraVerification';
import transaction from '@/v1/packages/common/store/modules/transaction';

import navigation from './navigation';
import applePay from './applePay';
import googlePay from './googlePay';
import apmPayment from './apmPayment';
import paymentErrorPopup from './paymentErrorPopup';

/* TODO: dependency inversion principle violation.
 * Store shouldn't be passed to low level library.
 *All needed modules should be passed to high level widget project */
export const install = store => {
  store.registerModule('transaction', transaction);
  store.registerModule(['transaction', 'navigation'], navigation);
  store.registerModule('quote', quote);
  store.registerModule('payoutDetailsFields', customFields());
  store.registerModule('extraVerificationFields', customFields());
  store.registerModule('cryptoTimer', cryptoTimer);
  store.registerModule('cardAuth', cardAuth);
  store.registerModule('card', cardStore);
  store.registerModule('cardSumsub', cardSumsubStore);
  store.registerModule('sumsubExtraVerification', sumsubExtraVerification);
  store.registerModule('applePay', applePay);
  store.registerModule('googlePay', googlePay);
  store.registerModule('apmPayment', apmPayment);
  store.registerModule('paymentErrorPopup', paymentErrorPopup);
};
