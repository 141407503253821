<template>
  <button
    class="menu-item"
    @click="$emit('click', alias)"
  >
    <div
      class="menu-item__text"
      :class="{
        [`menu-item__${alias}`]: true,
      }"
    >
      {{ title }}
    </div>

    <div
      v-if="isLanguageItem"
      class="menu-item__extra"
    >
      <i class="menu-item__icon svg-icon icon-earth" />
      {{ $t(`widget.supported-languages.${currentLocale}`) }}
    </div>
  </button>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { MENU_ITEMS_DICTIONARY } from '@/constants/menu';

export default defineComponent({
  name: 'MenuItem',

  props: {
    alias: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  emits: ['click'],

  setup(props) {
    const store = useStore();

    const currentLocale = computed(() => store.getters.locale);
    const isLanguageItem = props.alias === MENU_ITEMS_DICTIONARY.LANGUAGE.alias;

    return {
      currentLocale,

      isLanguageItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-item {
  display: flex;
  width: 100%;
  font-size: rem(16);
  padding: rem(12) 0;
  line-height: rem(20);
  border-top: 1px solid $black-06;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    font-size: calcVh(18, $widget-h-sm);
    padding: calcVh(12, $widget-h-sm) 0;
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    font-size: calcVh(18, $widget-h-xlg);
    padding: calcVh(12, $widget-h-xlg) 0;
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    font-size: calcVh(18, $widget-h-xxlg);
    padding: calcVh(12, $widget-h-xxlg) 0;
  }

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    border-bottom: 1px solid $black-06;
  }

  &__text {
    flex-grow: 1;
    font-weight: 700;
    text-align: left;
  }

  &__extra {
    margin-right: rem(16);
    display: flex;
    align-items: center;
  }

  &__icon {
    width: rem(16);
    height: rem(16);
    margin-right: rem(5);
  }

  &:after {
    display: block;
    content: '';
    width: rem(8);
    height: rem(12);
    background: url(@/v1/assets/images/widget/next.svg);
    background-size: cover;
    flex-grow: 0;
  }
}
</style>
