const ICON_BASE_URL = 'https://paywithmybank.com/start/assets/institutions/icons';

export default class TrustlyPaymentMethod {
    id;

    accountName;

    bankName;

    accountNumber;

    providerId;

    constructor({ id, accountName, bankName, accountNumber, providerId }) {
        this.id = id;
        this.accountName = accountName;
        this.bankName = bankName;
        this.accountNumber = accountNumber;
        this.providerId = providerId;
    }

    getId() {
        return this.id;
    }

    getAccountName() {
        return this.accountName;
    }

    getBankName() {
        return this.bankName;
    }

    getAccountNumber() {
        return this.accountNumber;
    }

    getProviderId() {
        return this.providerId;
    }

    getIconUrl(){
        return `${ICON_BASE_URL}/${this.providerId}.png`;
    }
}
