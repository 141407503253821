export default class Crypto {
  constructor({
    address = '',
    deposit_amount: depositAmount = '',
    qr = '',
    confirmations = '',
    transaction_hash: transactionHash = '',
    explorer_link: explorerLink = '',
    our_wallet_link: ourWalletLink = '',
  }) {
    this.address = address;
    this.depositAmount = depositAmount;
    this.addressLink = qr;
    this.confirmations = confirmations;
    this.transactionHash = transactionHash;
    this.explorerLink = explorerLink;
    this.ourWalletLink = ourWalletLink;
  }

  getAddress() {
    return this.address;
  }

  getDepositAmount() {
    return this.depositAmount;
  }

  getAddressLink() {
    return this.addressLink;
  }

  getConfirmations() {
    return this.confirmations;
  }

  getTransactionHash() {
    return this.transactionHash;
  }

  getExplorerLink() {
    return this.explorerLink;
  }

  getOurWalletLink() {
    return this.ourWalletLink;
  }
}
