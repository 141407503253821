<template>
  <div
    class="zero-hash-text"
    :class="{
      'zero-hash-mob zero-hash-mob--bottom': isMobile,
      'zero-hash-desk zero-hash-desk--bottom': !isMobile,
    }"
  >
    {{ $t("widget.zh.disclaimer") }}
  </div>
</template>

<script>

import { computed } from 'vue';

const SKINS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export default {
  name: 'ZhDisclaimer',
  props: {
    skin: {
      type: String,
      default: SKINS.DESKTOP,
      validator: value => [SKINS.DESKTOP, SKINS.MOBILE].indexOf(value) !== -1,
    },
  },
  setup(props) {
    const isMobile = computed(() => props.skin === SKINS.MOBILE);
    return {
      isMobile,
    };
  },
};
</script>

<style lang="scss" scoped>
.zero-hash {
  &-text {
    text-align: center;
    font-size: rem(12);
    line-height: rem(13);

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: calcVh(12, $widget-h-sm);
      line-height: calcVh(13, $widget-h-sm);
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: calcVh(12, $widget-h-xlg);
      line-height: calcVh(13, $widget-h-xlg);
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: calcVh(12, $widget-h-xxlg);
      line-height: calcVh(13, $widget-h-xxlg);
    }
  }

  &-mob {
    &--bottom {
      max-width: 608px;
      margin: rem(32) auto;
      color: $white;
      opacity: 0.5;
    }
  }

  &-desk {
    display: none;
    color: $white;
    max-width: rem(422);
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;

    &--bottom {
      margin-top: rem(56);
      margin-bottom: rem(30);

      @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
        margin-top: calcVh(56, $widget-h-sm);
      }

      @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
        margin-top: calcVh(56, $widget-h-xlg);
      }

      @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
        margin-top: calcVh(56, $widget-h-xxlg);
      }
    }
  }

  @media screen and (min-width: $laptop-min) {
    &-desk {
      display: block;
    }

    &-mob {
      display: none;
    }
  }
}
</style>
