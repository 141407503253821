import { captureException } from '../../services/sentry';
import eventBus from '../../helpers/eventBus';
import { getCorrelationId } from '@/v1/packages/common/services/correlation';

export function handleResponseError(error) {
  captureException({
    error,
    extra: {
      ...error.config,
    },
  });

  eventBus.emit('error', error);

  return Promise.reject(error);
}

export function addCorrelationId(config) {
  config.headers['X-Correlation-ID'] = getCorrelationId();
  return config;
}
