import { readonly, ref } from 'vue';

const styles = ref({});

export const useWidgetMenuStyles = defaultValue => {
  if (defaultValue) {
    styles.value = defaultValue;
  }

  return {
    widgetMenuStyles: readonly(styles),
    setWidgetMenuStyles: value => {
      styles.value = value;
    },
  };
};
