import store from '@/v1/store/index';
import { getLayout, canClose } from '@/services/widgetSettings';

export const getReturnUrl = () => {
  const parsedUrl = new URL(window.location.href);

  const searchParams = new URLSearchParams();
  searchParams.set('requestId', store.getters.requestId);
  searchParams.set('layout', getLayout());
  searchParams.set('canClose', canClose() ? 'true' : 'false');

  if (store.getters.successReturnURL) {
    searchParams.set('successReturnUrl', store.getters.successReturnURL);
  }

  if (store.getters.failureReturnURL) {
    searchParams.set('failureReturnUrl', store.getters.failureReturnURL);
  }

  return `${parsedUrl.origin}${parsedUrl.pathname}?${searchParams.toString()}`;
};
