import { isObject } from '../utils/types';

export const verificationNormalizer = ({
  verificationResponse,
  livenessResponse,
}) => {
  const {
    data: verificationData,
    status: verificationStatus,
  } = verificationResponse;

  const { data: livenessData, status: livenessStatus } = livenessResponse;

  if (livenessStatus !== 200 || verificationStatus !== 200) {
    throw new Error('Response failed with non-successful status');
  }

  if (!isObject(livenessData) || !isObject(verificationData)) {
    throw new Error('Cannot normalize response');
  }

  return {
    // Verification
    status: verificationData.status,
    email: verificationData.email,
    comment: verificationData.comment,
    fields: verificationData.fields,

    // Liveness
    isSumsubLivenessEnabled: livenessData.isSumsubLivenessEnabled,
    accessToken: livenessData.accessToken,
    flowName: livenessData.flowName,
    apiUrl: livenessData.apiUrl,
  };
};
