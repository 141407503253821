const ErrorTypeEnum = Object.freeze({
  GENERAL_ERROR: Symbol('general_error'),
  FAILED_TO_RUN: Symbol('failed_to_run'),
  INACTIVE_USER: Symbol('inactive_user'),
  USER_SESSION_EXPIRED: Symbol('user_session_expired'),
  CRITICAL_ERROR: Symbol('critical_error'),
  UNHANDLED_ERROR: Symbol('unhandled_error'),
  UNKNOWN_ERROR: Symbol('unknown_error'),
  TRANSACTION_FUNNEL_ERROR: Symbol('transaction_funnel_error'),
  TRANSLATION_LOADING_ERROR: Symbol('translation_loading_error'),
});

export default ErrorTypeEnum;
