import store from '@/v1/store';
import { isV2 } from '@/services/apiVersion';
import { NAME as TRANSACTION } from './transaction';
import { NAME as EXCHANGE_FORM } from './exchangeForm';
import { NAME as EXCHANGE_FORM_V2 } from './exchangeFormV2';
import { isNewExchangeFormFlowEnabled } from '@/v1/services/exchangeFormFlow';

export const NAME = 'start-transaction';

export default {
  path: '/start-transaction',
  name: NAME,
  redirect: () => {
    if (isNewExchangeFormFlowEnabled()) {
      return TRANSACTION;
    }

    if (!store.getters.isRequestWithQuote && !store.getters['external/funnelInvoiceId']) {
      if (isV2()) {
        return EXCHANGE_FORM_V2;
      }

      return EXCHANGE_FORM;
    }

    return TRANSACTION;
  },
};
