import { version as packageVersion } from '../../../../package.json';
import { captureMessage } from '@/v1/packages/common/plugins/sentry';

import themes from './constants';

export const getThemeName = themeId => {
  const themeName = themes[themeId] || null;

  if (themeName === null) {
    throw new Error(`Theme with id ${themeId} does not exist. Please contact your provider`);
  }

  return themeName;
};

export const loadPartnersCss = themeName => new Promise((resolve, reject) => {
  if (!themeName) {
    reject();

    return;
  }

  const link = document.createElement('link');
  const partnerFileName = themeName.toLowerCase();
  // eslint-disable-next-line no-undef
  const filename = `${import.meta.env.VITE_APP_BASE_DIR}partners/${partnerFileName}/styles.css?${packageVersion}`;
  link.rel = 'stylesheet';
  link.href = filename;
  link.onload = resolve;

  const { head } = document;
  head.appendChild(link);
});

export const loadPartnerJSON = themeName => {
  const configList = import.meta.glob('/public/partners/*/config.json');

  const partnerJson = configList[`/public/partners/${themeName}/config.json`];
  if (partnerJson) {
    return partnerJson();
  }

  captureMessage('Failed to load theme config', {
    themeName,
  });

  throw new Error('Failed to load theme config');
};

export const isCoinmamaTheme = (themeName = '') => themeName.toLowerCase() === themes.coinmama;
