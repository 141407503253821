import { TransactionFlowStep } from '../models/steps';

export class TransactionFlowStepFactory {
  constructor(transaction, payment, extraVerification, baseVerification) {
    this.transaction = transaction;
    this.payment = payment;
    this.extraVerification = extraVerification;
    this.baseVerification = baseVerification;
  }

  build() {
    if (this.transaction.isOct()) {
      return this.processSteps(this.getOctSteps());
    }

    if (this.payment.isBank() || this.payment.isBankSwift()) {
      return this.processSteps(this.getBankSteps());
    }

    if (this.payment.isCrypto()) {
      return this.processSteps(this.getCryptoSteps());
    }

    if (this.payment.isElectronic()) {
      return this.processSteps(this.getElectronicSteps());
    }

    if (this.payment.isBankCard()) {
      return this.processSteps(this.getBankCardSteps());
    }

    if (this.payment.isGiftCard()) {
      return this.processSteps(this.getGiftCardSteps());
    }

    if (this.payment.isApm()) {
      return this.processSteps(this.getApmSteps());
    }

    throw new Error('Can not build steps');
  }

  processSteps(steps) {
    steps = steps.map(step => ({ name: step }));

    if (this.extraVerification.isNone()) {
      steps = steps.filter(
        step => step.name !== TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      );
    }

    return steps;
  }

  getOctSteps() {
    return [
      TransactionFlowStep.STEP_CARD_AUTHORIZATION,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getBankSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getCryptoSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getElectronicSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getBankCardSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getGiftCardSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_GIFT_CARD,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_REVIEW,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }

  getApmSteps() {
    return [
      TransactionFlowStep.STEP_PAYOUT_DETAILS,
      TransactionFlowStep.STEP_EXTRA_VERIFICATION,
      TransactionFlowStep.STEP_PAYMENT_DETAILS,
      TransactionFlowStep.STEP_PAYMENT_WAITING,
      TransactionFlowStep.STEP_PAYOUT_WAITING,
      TransactionFlowStep.STEP_COMPLETED,
    ];
  }
}
