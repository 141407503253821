import { computed, ref } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import moment from 'moment';
import { errorService, ErrorType } from '@/v1/services/ErrorService';
import getParams from './utils/getParams';

// local translations
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import ru from '@/locales/ru.json';
import it from '@/locales/it.json';
import fr from '@/locales/fr.json';
import es from '@/locales/es.json';
import pt from '@/locales/pt.json';
import ko from '@/locales/ko.json';

import store from '@/v1/store';

const fallbackLocale = import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE;

const defaultI18nOptions = {
  warnHtmlInMessage: 'off',
  legacy: false,
  globalInjection: true,
  fallbackLocale,
  missing: () => '',
  silentFallbackWarning: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
};

if (import.meta.env.NODE_ENV === 'development') {
  defaultI18nOptions.locale = import.meta.env.VITE_APP_I18N_LOCALE;
}

defaultI18nOptions.messages = { en, ru, de, it, fr, es, pt, ko };

const modifyMessages = messages => {
  // PD-6998: remove read more link from translation
  const tooltipMessageKey = 'transaction-flow.tooltip.message';

  messages[tooltipMessageKey] = messages[tooltipMessageKey].replace(/<a.*?>.*?<\/a>/ig, '');

  return messages;
};

const i18n = {};
const loadedLanguages = ref([]);

export const isAnyTranslationLoaded = computed(() => loadedLanguages.value.length > 0);

export function setupI18n(options = {}) {
  const i18nOptions = {
    ...defaultI18nOptions,
    ...options,
  };
  const i18nInstance = createI18n(i18nOptions);

  Object.assign(i18n, i18nInstance);

  return i18n;
}

export async function setI18nLanguage(lang, requestId, translationResource) {
  const isLanguageAlreadyLoaded = loadedLanguages.value.includes(lang);
  const isCurrentLanguage = loadedLanguages.value.includes(lang);

  if (isLanguageAlreadyLoaded && isCurrentLanguage) {
    setAppLanguage(lang);
    await store.dispatch('endLoadTranslation');
    return;
  }

  await store.dispatch('startLoadTranslation');

  try {
    let serverMessages = {};
    if (requestId) {
      serverMessages = await getServerMessages(lang, requestId, translationResource);
    }

    loadedLanguages.value.push(lang);

    const modifiedServerMessages = modifyMessages(serverMessages);

    i18n.global.setLocaleMessage(lang, {
      ...(i18n.global.messages.value[lang] || {}),
      ...modifiedServerMessages,
    });

    setAppLanguage(lang);
  } catch (error) {
    if (isAnyTranslationLoaded.value) {
      store.dispatch('setLocale', i18n.global?.locale || fallbackLocale);
    } else if (translationResource !== '') {
      // In case when resource is incorrect, we can reset it and try again with fallback
      // url for current run-time requestId, but only once
      const { requestId: requestIdFromUrl } = getParams();

      if (requestIdFromUrl) {
        setI18nLanguage(lang, requestIdFromUrl);
        return;
      }
    }

    errorService.push(ErrorType.TRANSLATION_LOADING_ERROR, error);
  } finally {
    store.dispatch('endLoadTranslation');
  }
}

function setAppLanguage(lang) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = lang;
  } else {
    i18n.global.locale.value = lang;
  }

  // TODO: get rid of moment.js
  moment.locale(lang);

  const htmlElement = document.querySelector('html');
  if (htmlElement) {
    htmlElement.setAttribute('lang', lang);
  }
  return lang;
}

async function getServerMessages(lang, requestId, translationResource) {
  const fallbackUrl = `${import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL}/v1/translation/${requestId}/${lang}`;
  const url = translationResource !== '' ? translationResource : fallbackUrl;

  return axios.get(url)
    .then(({ data }) => data)
    .catch(() => axios.get(fallbackUrl).then(({ data }) => data));
}
