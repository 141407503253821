import { TransactionFlowStep as steps } from '../models/steps';
import Natural from './natural';

export default class extends Natural {
  constructor(transaction, steps, currentIndex, payment, isChallenge = false) {
    super(transaction, steps, currentIndex);
    this.payment = payment;
    this.isChallenge = isChallenge;
  }

  getStepIndex() {
    return this.steps.findIndex(
      step => step.name === steps.STEP_PAYMENT_WAITING,
    );
  }

  current() {
    if (this.getCurrentIndex() <= 0 || !this.isChallenge) {
      return this.getSteps().find(
        step => step.name === steps.STEP_PAYMENT_WAITING,
      );
    }

    // TODO: hotfix; refactor this
    if (
      this.getSteps()[this.getCurrentIndex()].name
        === steps.STEP_EXTRA_VERIFICATION
      || this.payment.isCrypto()
    ) {
      return this.next();
    }

    return this.getSteps()[this.getCurrentIndex()];
  }

  hasPrev() {
    return this.payment.isBank() || this.payment.isBankSwift();
  }
}
