import { isStatusCritical, isStatusUnauthorized } from '../services/error';

const USER_BANNED = 'ACCESS_DENIED_TO_BANNED_USER';
const GOOGLE_PAY_DECLINED = 'AUTH_REQUESTED_PAYMENT_CREATION_ERROR';

export default {
  namespaced: true,
  state: {
    status: null,
    redirectLink: null,
    error: {},
  },
  getters: {
    status: state => state.status,
    error: state => state.error,
    isCriticalError: state => isStatusCritical(state.status),
    isUnauthorized: state => isStatusUnauthorized(state.status),
    hasRedirectLink: state => state.redirectLink !== null,
    redirectLink: state => state.redirectLink,
    errorCode: state => {
      const { response = {} } = state.error || {};
      const { data = { code: null } } = response;

      return data.code;
    },
    isUserBanned: state => state.errorCode === USER_BANNED,
    isGooglePayDeclined: state => state.errorCode === GOOGLE_PAY_DECLINED,
  },
  mutations: {
    setStatus(state, payload) {
      state.status = payload;
    },
    setRedirectLink(state, payload) {
      state.redirectLink = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    resetState(state) {
      state.error = {};
      state.status = null;
      state.redirectLink = null;
    },
  },
  actions: {
    setError({ commit }, error) {
      try {
        const response = error.response || error;

        const { status, headers } = response;

        commit('setStatus', status);

        if ('location' in headers) {
          commit('setRedirectLink', headers.location);
        }

        commit('setError', error);
      } catch (e) {
        // do nothing
      }
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
};
