import CardPaymentClient from '../../services/clients/card-payment-client';
import AbstractPaymentProcessor from '../AbstractPaymentProcessor';

export default class WorldpayPaymentProcessor extends AbstractPaymentProcessor {
  constructor(cardId, invoice) {
    super('worldpay', cardId, invoice);
  }

  pay() {
    return CardPaymentClient.getDdc({ cardId: this.getCardId() }).then(
      ({ data }) => ({
        isForm: true,
        data,
      }),
    );
  }

  initApplePayPayment(paymentRequest) {
    paymentRequest.invoice = this.getInvoice();
    paymentRequest.cardId = this.getCardId();

    return CardPaymentClient.initialWorldpayApplePayPayment(
      paymentRequest,
    ).then(({ data }) => {
      const {
        is_challenge_required: isChallengeRequired,
        challenge_info: challengeInfo,
        payment_id: paymentId,
      } = data;

      return { isChallengeRequired, challengeInfo, paymentId };
    });
  }

  initGooglePayPayment() {
    return this.pay();
  }
}
