const SECOND_IN_MILLISECONDS = 1000;

const fnsToExecute = {};

setInterval(() => {
  const now = new Date();
  Object.getOwnPropertySymbols(fnsToExecute).forEach(smb => fnsToExecute[smb](now));
  Object.values(fnsToExecute).forEach(exec => exec(now));
}, SECOND_IN_MILLISECONDS);

export const executeEverySecond = (executor, immediate) => {
  if (immediate) {
    const now = new Date();
    executor(now);
  }
  const executorKey = Symbol();
  fnsToExecute[executorKey] = executor;
  return executorKey;
};

export const removeExecutionById = executorId => {
  delete fnsToExecute[executorId];
};
