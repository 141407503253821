export default class Bank {
  constructor({
    bank_address: bankAddress = '',
    bank_name: bankName = '',
    bank_swift: bankSwift = '',
    comment = '',
    deposit_amount: depositAmount = '',
    iban = '',
    receiver = '',
    reg_nr: regNr = '',
    beneficiary_address: beneficiaryAddress = '',
    local_code: localCode = '',
    account_nr: accountNr = '',
    beneficiary_account_number: beneficiaryAccountNumber = '',
    beneficiary_bank_address: beneficiaryBankAddress = '',
    beneficiary_bank_bic: beneficiaryBankBic = '',
    beneficiary_bank_name: beneficiaryBankName = '',
    beneficiary_name: beneficiaryName = '',
  }) {
    this.bankAddress = bankAddress;
    this.bankName = bankName;
    this.bankSwift = bankSwift;
    this.comment = comment;
    this.depositAmount = depositAmount;
    this.iban = iban;
    this.receiver = receiver;
    this.regNr = regNr;
    this.beneficiaryAddress = beneficiaryAddress;
    this.localCode = localCode;
    this.accountNr = accountNr;
    this.beneficiaryAccountNumber = beneficiaryAccountNumber;
    this.beneficiaryBankAddress = beneficiaryBankAddress;
    this.beneficiaryBankBic = beneficiaryBankBic;
    this.beneficiaryBankName = beneficiaryBankName;
    this.beneficiaryName = beneficiaryName;
  }

  getBankAddress() {
    return this.bankAddress;
  }

  getBankName() {
    return this.bankName;
  }

  getBankSwift() {
    return this.bankSwift;
  }

  getComment() {
    return this.comment;
  }

  getDepositAmount() {
    return this.depositAmount;
  }

  getIban() {
    return this.iban;
  }

  getReceiver() {
    return this.receiver;
  }

  getRegNr() {
    return this.regNr;
  }

  getBeneficiaryAddress() {
    return this.beneficiaryAddress;
  }

  getLocalCode() {
    return this.localCode;
  }

  getAccountNr() {
    return this.accountNr;
  }

  getBeneficiaryAccountNumber(){
    return this.beneficiaryAccountNumber;
  }

  getBeneficiaryBankAddress(){
    return this.beneficiaryBankAddress;
  }

  getBeneficiaryBankBic(){
    return this.beneficiaryBankBic;
  }

  getBeneficiaryBankName(){
    return this.beneficiaryBankName;
  }

  getBeneficiaryName() {
    return this.beneficiaryName;
  }
}
