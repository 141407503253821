import axios from 'axios';
import axiosRetry from 'axios-retry';

import { addAuthHeader, refreshExpiredToken } from './interceptors/authInterceptors.js';
import { checkResponseBody, checkErrorBody } from './interceptors/emptyBodyInterceptors.js';
import { handleResponseError, addCorrelationId } from './interceptors/errorInterseptors.js';
import { addUserLocaleHeader } from './interceptors/userLocaleInterceptor';
import { addPartnerHeader } from './interceptors/userPartnerIntercepor';

const noopSuccess = Promise.resolve.bind(Promise);
const noopFail = Promise.reject.bind(Promise);

const factory = axiosOptions => {
  const instance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...axiosOptions,
  });

  axiosRetry(instance, {
    retries: 1,
    retryDelay: retryCount => retryCount * 500,
  });

  // order matters
  instance.interceptors.request.use(addAuthHeader, noopFail);
  instance.interceptors.request.use(addCorrelationId, noopFail);
  instance.interceptors.request.use(addUserLocaleHeader, noopFail);
  instance.interceptors.request.use(addPartnerHeader, noopFail);

  instance.interceptors.response.use(checkResponseBody, checkErrorBody);
  instance.interceptors.response.use(noopSuccess, handleResponseError);
  instance.interceptors.response.use(noopSuccess, refreshExpiredToken.bind(instance));

  return instance;
};

export default factory;
