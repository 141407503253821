import { computed } from 'vue';
import { useStore } from 'vuex';

export function useIsTransactionInFinalStatus() {
  const store = useStore();

  return computed(() => {
    const transaction = store.getters['external/funnelTransaction'] || null;

    if (!transaction) {
      return false;
    }

    return transaction.isCancelled() || transaction.isCompleted() || transaction.isRejected();
  });
}
