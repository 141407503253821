export function checkResponseBody(response) {
  if (response === null) {
    throw new Error('No response from server');
  }
  return response;
}

export function checkErrorBody(error) {
  if (error.response === undefined) {
    throw new Error(`Undefined response from server: ${error.message}`);
  }
  return Promise.reject(error);
}
