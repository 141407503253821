import { CardAuthorizationStatus } from '../../models/card/status';

export default {
  namespaced: true,
  state: {
    status: null,
    error: null,
  },
  getters: {
    status: state => state.status,
    isPending: state => state.status === CardAuthorizationStatus.STATUS_PENDING,
    isFailed: state => state.status === CardAuthorizationStatus.STATUS_FAILED,
    error: state => state.error,
  },
  mutations: {
    setStatus(state, payload) {
      state.status = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    init({ commit, dispatch }, { status, error }) {
      commit('setStatus', status);
      if (error) {
        commit('setError', error);
      }
    },
  },
};
