<template>
  <img
    :src="icon"
    :alt="type"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  type: string,
}>();

const icon = new URL(`../assets/images/info-${props.type}.svg`, import.meta.url).href;
</script>
