import ApmApi from '@/v1/packages/common/services/clients/apm-client';
import BridgerPayService from '@/v1/packages/common/services/bridger-pay-service';
import TrustlyPaymentMethod from '@/v1/packages/common/models/trustlyPaymentMethod';

export default {
  namespaced: true,
  state: {
    isApmPaymentAlreadyInitiated: false,
    isApmPaymentReady: false,
    isLoading: true,
    trustlyPaymentMethods: null,
  },
  getters: {
    isApmPaymentAlreadyInitiated: state => state.isApmPaymentAlreadyInitiated,
    isApmPaymentReady: state => state.isApmPaymentReady,
    isLoading: state => state.isLoading,
    trustlyPaymentMethods: state => state.trustlyPaymentMethods,
  },
  mutations: {
    setIsApmPaymentAlreadyInitiated(state) {
      state.isApmPaymentAlreadyInitiated = true;
    },
    setIsTrustlyAlreadyInitiated(state) {
      state.isTrustlyAlreadyInitiated = true;
    },
    setIsApmPaymentReady(state) {
      state.isApmPaymentReady = true;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setTrustlyPaymentMethods(state, value) {
      state.trustlyPaymentMethods = value;
    },
  },
  actions: {
    async initBridgerPay({ commit, getters }, { invoice, returnUrl }) {
      try {
        const response = await ApmApi.getCashierToken({
          invoice,
          return_url: returnUrl,
        });

        const { cashier_key, cashier_token, provider } = response.data[0];
        await BridgerPayService.init({
          cashierKey: cashier_key,
          cashierToken: cashier_token,
          singlePaymentProvider: provider,
        });
        commit('setIsApmPaymentReady');
      } catch (e) {
        if (e.status === 409) {
          commit('setIsApmPaymentAlreadyInitiated');
          return;
        }

        throw e;
      } finally {
        commit('setIsLoading', false);
      }
    },
    async loadTrustlyPaymentMethods({ commit }) {
      try {
        const { data } = await ApmApi.getTrustlyPaymentMethods();
        const trustlyPaymentMethods = data.map(el => new TrustlyPaymentMethod(el));
        commit('setTrustlyPaymentMethods', trustlyPaymentMethods);
      } finally {
        commit('setIsLoading', false);
      }
    },
  },
};
