import client from '@/v1/network/clients/assets';

export const fetchCryptoAssets = () => {
  return client.get('')
    .then(response => {
      const blockchains = response.data.blockchains.reduce((blockchains, blockchain) => {
        const { id, name, network } = blockchain;
        blockchains[id] = { name, network };

        return blockchains;
      }, {})

      return response.data.assets.reduce((assets, asset) => {
        assets[asset.id] = {
          ...asset,
          blockchainName: blockchains[asset.blockchain].name,
          networkName: blockchains[asset.blockchain].network?.[asset.network]?.name,
        };

        return assets;
      }, {});
    });
};
