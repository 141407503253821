import { matchStringByTagUl } from '../services/util';

export default class Hint {
  constructor(value) {
    this.value = value;

    this.primary = null;
    this.secondary = null;

    this.splitValue();
  }

  splitValue() {
    if (!this.hasValue()) {
      return;
    }

    const values = matchStringByTagUl(this.value);

    if (values === null) {
      this.primary = this.value;
      return;
    }

    this.primary = values[0];
    this.secondary = values[1];
  }

  hasValue() {
    return this.value !== null && typeof this.value !== 'undefined';
  }

  getValue() {
    return this.value;
  }

  hasPrimary() {
    return this.primary !== null;
  }

  getPrimary() {
    return this.primary;
  }

  hasSecondary() {
    return this.secondary !== null;
  }

  getSecondary() {
    return this.secondary;
  }
}
