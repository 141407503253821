class PartnerService {
  constructor() {
    this.partner = null;
  }

  setPartner(partner) {
    this.partner = partner;
  }

  getPartner() {
    if (this.partner === null) {
      throw new Error('Trying to get non-existing partner');
    }

    return this.partner;
  }
}

export default new PartnerService();
