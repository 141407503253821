import { SumsubConfig } from '../../models/sumsub';
import ProcessingClient from '../../services/clients/processing-client';

export default {
  namespaced: true,
  state: {
    sumsubConfig: new SumsubConfig(),

    isInvestorCategorisation: false,
  },
  getters: {
    sumsubConfig: state => state.sumsubConfig,
    isInvestorCategorisation: state => state.isInvestorCategorisation,
  },
  mutations: {
    setSumsubConfig(state, payload) {
      state.sumsubConfig = payload;
    },
    setInvestorCategorization(state, payload) {
      state.isInvestorCategorisation = payload;
    },
  },
  actions: {
    getConfig({ commit, getters, rootGetters }, verificationFieldTypeSlug) {
      const QUESTIONNAIRE_SLUG_STABLE = 'crypto-appropriateness-assessment-questionnaire-stable';
      const QUESTIONNAIRE_SLUG_ESTABLISHED = 'crypto-appropriateness-assessment-questionnaire-established';
      const QUESTIONNAIRE_SLUG_INVESTOR = 'investor-categorization-questionnaire';
  
      if (
        rootGetters['extraVerificationFields/fields'][0].hasCoolOffTimer()
          && (verificationFieldTypeSlug === QUESTIONNAIRE_SLUG_STABLE || verificationFieldTypeSlug === QUESTIONNAIRE_SLUG_ESTABLISHED)
      ) {
        return;
      }

      if (verificationFieldTypeSlug === QUESTIONNAIRE_SLUG_INVESTOR) {
        commit('setInvestorCategorization', true);
      }

      return ProcessingClient.getExtraVerificationConfig(
          verificationFieldTypeSlug,
        ).then(({ data }) => {
          const { apiUrl, levelName, accessToken, email } = data;
          commit(
            'setSumsubConfig',
            new SumsubConfig(true, accessToken, levelName, apiUrl, email),
          );
        });
      },
    },
};
