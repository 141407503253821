import MoneyService from '../moneyService';

export default class Payout extends MoneyService {
  constructor(
    name = null,
    icon = null,
    slug = null,
    type = null,
    account = null,
    canAuthorizeCard = null,
    crypto = {},
    iconName = null,
    binanceDetails = {},
  ) {
    super(name, icon, slug, type, iconName);

    this.account = account;
    this.canAuthorizeCard = canAuthorizeCard;
    this.setCrypto(crypto);
    this.binanceId = null;
    this.binanceInternalTransaction = false;

    // Need it because backend returns null instead of undefined
    if (binanceDetails) {
      const {
        binance_id: binanceId = null,
        internal_transaction: internalTransaction = false,
      } = binanceDetails;

      this.binanceId = binanceId;
      this.binanceInternalTransaction = internalTransaction;
    }
  }

  isInternalBinanceTransaction() {
    return this.binanceInternalTransaction;
  }

  getBinanceId() {
    return this.binanceId;
  }

  setCrypto({
    transaction_hash = null,
    explorer_link = null,
    tag = null,
    memo = null,
    assetId = null,
    blockchain = {},
  }) {
    // eslint-disable-line camelcase
    this.tag = tag;
    this.memo = memo;
    this.transactionHash = transaction_hash; // eslint-disable-line camelcase
    this.explorerLink = explorer_link; // eslint-disable-line camelcase
    this.blockchain = blockchain;
    this.assetId = assetId;
  }

  getAccount() {
    return this.account;
  }

  hasTag() {
    return this.account && this.tag !== null;
  }

  getTag() {
    return this.tag;
  }

  hasMemo() {
    return this.account && this.memo !== null;
  }

  getMemo() {
    return this.memo;
  }

  getTransactionHash() {
    return this.transactionHash;
  }

  getExplorerLink() {
    return this.explorerLink;
  }

  getCryptoCode() {
    return this.blockchain.feeCurrency;
  }

  getBlockchainName() {
    return this.blockchain.name;
  }

  getAssetId() {
    return this.assetId;
  }

  cardAuthorizable() {
    return this.canAuthorizeCard === true;
  }

  isMobileCoin() {
    return this.blockchain.name === 'mobilecoin';
  }
}
