import Api from '../api';

const apiUrl = `${import.meta.env.VITE_APP_PROCESSING_API_URL}/public/apm-service/v1`; // eslint-disable-line no-undef

export default {
  getCashierToken(body) {
    const url = `${apiUrl}/cashier-token`;

    return Api().post(url, body);
  },
  getPayment(invoice) {
    const url = `${apiUrl}/payment/invoice/${invoice}`;

    return Api().get(url);
  },
  getTrustlyPaymentMethods() {
    const url = `${apiUrl}/trustly/payment-methods`;

    return Api().get(url);
  },
  trustlyCreatePayment(data) {
    const url = `${apiUrl}/payment/trustly/create`;

    return Api().post(url, data);
  },
  trustlyInitSession(params) {
    const url = `${apiUrl}/trustly/init-session`;

    return Api().post(url, params);
  },
};
