<template>
  <Loader v-if="isLanguageSwitching" />
  <PageLayout
    v-else
    :have-previous-step="true"
    :title="$t('widget.language')"
    header-icon="earth"
    class="small-title"
    @previous-step="handlePreviousStep"
  >
    <LanguageSwitcher class="language__switcher" />
  </PageLayout>
</template>

<script>
import { watch, computed } from 'vue';
import { useStore } from 'vuex';

import Loader from '@/v1/components/Loader';
import PageLayout from '@/v1/components/PageLayout';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  name: 'Language',

  components: {
    Loader,
    PageLayout,
    LanguageSwitcher,
  },

  emits: ['previous-step'],

  setup(props, { emit }) {
    const store = useStore();

    const isLanguageSwitching = computed(() => store.getters.isLanguageSwitching);
    const handlePreviousStep = () => emit('previous-step');

    watch(() => isLanguageSwitching.value, value => {
      if (!value) {
        handlePreviousStep();
      }
    });

    return {
      isLanguageSwitching,

      handlePreviousStep,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/v1/assets/scss/blocks/small-title";

.language {
  &__icon {
    width: rem(32);
    height: rem(32);
    margin-right: rem(12);

    @media (max-width: $tablet-max) {
      width: rem(24);
      height: rem(24);
    }
  }

  &__switcher {
    margin-bottom: rem(32);
  }
}
</style>
