<template>
  <PageLayout
    :have-previous-step="true"
    :title="$t('widget.terms-of-use.title')"
    class="scrollable small-title"
    @previous-step="handlePreviousStep"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="$t('widget.pages.terms_of_use')" />
  </PageLayout>
</template>

<script>
import { defineComponent } from 'vue';

import PageLayout from '@/v1/components/PageLayout';

export default defineComponent({
  name: 'TermsOfUse',

  components: {
    PageLayout,
  },

  emits: ['previous-step'],

  setup(props, { emit }) {
    const handlePreviousStep = () => emit('previous-step');

    return {
      handlePreviousStep,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/v1/assets/scss/blocks/link";
@import "@/v1/assets/scss/blocks/scrollable";
@import "@/v1/assets/scss/blocks/small-title";
</style>
